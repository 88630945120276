
const whatsAppURL = "https://wa.me/+91";
const gmailURL = "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=";
const gmailSubject = "&su=";
const gmailBody = "&body=";
const facebookURL = "https://www.facebook.com/";
const instagramURL = "https://www.instagram.com/";
const twitterURL = "https://twitter.com/";
const youtubeURL = "https://www.youtube.com/";

// const userDetailsRoute = "http://localhost:3000/users/UserDetails/";
// const userDetailsRoute = "https://talenthunt.azurewebsites.net/users/UserDetails/";   // --- TalentHunt DEV--> DetailPost.jsx
// const userDetailsRoute = "https://yashrajfilms.azurewebsites.net/users/UserDetails/";   // --- YRF --> DetailPost.jsx
// const userDetailsRoute = "https://dev-casting-app.azurewebsites.net/users/UserDetails/";   // --- YRF MAIN DEV--> DetailPost.jsx
const userDetailsRoute = "https://uat-casting-app.azurewebsites.net/users/UserDetails/";   // --- YRF MAIN UAT--> DetailPost.jsx
// const userDetailsRoute = "https://yrfcasting.com/users/UserDetails/";   // --- YRF MAIN PROD--> DetailPost.jsx

// const stagesListData = [
//     { value: '01', label: 'Selected by Assistant casting director' },
//     { value: '02', label: 'Rejected by Assistant casting director' },
//     { value: '03', label: 'Selected by casting director' },
//     { value: '04', label: 'Rejected by casting director' },
//     { value: '05', label: 'Selected by Director' },
//     { value: '06', label: 'Rejected by Director' }
// ]

const stagesListData = [
    { value: 1, label: 'Stage 1 (Assistant Casting Director)' },
    { value: 2, label: 'Stage 2 (Casting Director)' },
    { value: 3, label: 'Stage 3 (Director)' }
]

const verficationList = [
    { value: 1, label: 'Is Verified' },
    { value: 2, label: 'Not Verified' },
    { value: 3, label: 'Both' }
]

// const yrfStagesListData = [
//     {
//         label: 'Casting Director',
//         value: 1
//     },
//     {
//         label: 'Director',
//         value: 2
//     },
// ]

const yrfStagesListData = [
    {
        label: 'Director',
        value: 1
    },
    {
        label: 'Casting Director',
        value: 2
    },
    {
        label: 'Production User',
        value: 3
    }
]

const stageStatus = [
    { value: 1, label: 'Selected' },
    { value: 2, label: 'On Hold' },
    { value: 3, label: 'Not Selected' }
]

const countries = [
    {
        label: 'India',
        value: 1
    }
]

const roles = [
    {
        label: 'Director',
        value: 1
    },
    {
        label: 'Casting Director',
        value: 2
    },
    {
        label: 'Assistant Casting Director',
        value: 3
    }
]

const yrfRoles = [
    {
        label: 'Director',
        value: 1
    },
    {
        label: 'Casting Director',
        value: 2
    },
    {
        label: 'Production Team',
        value: 4
    }
]
const budgetStatus = [
    {
        label: 'Approve',
        value: 1
    },
    {
        label: 'Pending',
        value: 2
    },
    {
        label: 'Reject',
        value: 3
    },
]

const areaTypes = [
    {
        label: "Urban",
        value: 1
    },
    {
        label: "Rural",
        value: 2
    }
];

const ageCategory = [
    {
        label: "18-30",
        value: 1
    },
    {
        label: "30-40",
        value: 2
    },
    {
        label: "40-50",
        value: 3
    },
    {
        label: "50 Above",
        value: 4
    },
];

const auditionTypes = [
    {
        label: "On Site",
        value: 1
    },
    {
        label: "Online",
        value: 2
    },
];

const gender = [
    {
        label: "Both",
        value: 2
    },
    {
        label: "Female",
        value: 1
    },
    {
        label: 'Male',
        value: 0
    }
];

const yrfGender = [
    {
        label: 'Male',
        value: 0
    },
    {
        label: "Female",
        value: 1
    },
    {
        label: "Others",
        value: 3
    },
];

const stageNotifMsgSeleceted = "Congratulations!🤩 We are pleased to inform you that you have been selected for {stage_value}. We appreciate your effort and dedication in the audition. We will update you shortly with details about the next steps of the process"
const stageNotifMsgOnHold = "We would like to update you that your audition for {stage_value} is currently under review. Our team is working to evaluate your performance, and we will reach out to you as soon as the review is completed."
const stageNotifMsgRejected = "We regret to inform you that you have not been selected for this audition. We appreciate your time and effort invested in the process. Thank you for your interest. We encourage you to continue pursuing your passion and exploring other audition opportunities.";

export {
    whatsAppURL,
    gmailURL,
    gmailSubject,
    gmailBody,
    facebookURL,
    instagramURL,
    twitterURL,
    youtubeURL,
    stagesListData,
    stageStatus,
    areaTypes,
    ageCategory,
    auditionTypes,
    yrfGender,
    stageNotifMsgSeleceted,
    stageNotifMsgOnHold,
    stageNotifMsgRejected,
    userDetailsRoute,
    roles,
    countries,
    yrfRoles,
    budgetStatus,
    yrfStagesListData,
    verficationList
}