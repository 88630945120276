import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { Col, Table } from 'react-bootstrap';
import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const PrivacyPolicy = () => {

    const navigate = useNavigate();


    return (
        <DocumentTitle title={'Privacy Policy'}>
            <>
                <Helmet>
                    <link rel="stylesheet" type="text/css" href="css/styles.css" />
                </Helmet>
                <div>

                    <body style={{
                        fontFamily: 'sans-serif,Arial',
                        margin: 0,
                        padding: 0,
                    }}>
                        <section id="logo-home">
                            <img src="images/yrf-casting-logo.png" />
                        </section>
                        <header style={{
                            backgroundColor: '#ffffff',
                            color: '#000000',
                            textAlign: 'center',
                            padding: '20px'
                        }}>
                            <h1>Privacy Policy</h1>
                        </header>
                        <main style={{ margin: '20px' }}>
                            <p>This mobile application YRF Casting is owned and operated by Yash Raj Films Private Limited <b>(we, our, us, Company, YRF)</b>. We are committed towards protecting the personal data of all individuals who access our Platform, to access our Services.   </p>
                            <p>This privacy policy (Privacy Policy/ Policy) explains the details of our collection, use, purpose, sharing, and disclosure of data, including your personal data, while offering our Services to you. This Privacy Policy applies regardless of whether you have downloaded or accessed the Platform from your mobile telephone or handheld device to access our Services. This Privacy Policy does not cover any data collection or processing, conducted offline, or through our products or services that do not link directly with this Privacy Policy, or by third-party sites that we may link to.  </p>
                            <p>This Privacy Policy must be read in conjunction and together with the Terms and Conditions, available at: <a href="https://yrfcasting.com/termsofservice" style={{ color: 'grey' }}>https://www.yrfcasting.com/terms-and-condition</a>. The defined terms shall have the meaning ascribed to them within the Terms and Conditions. </p>

                            <ol><p><strong>Data We Collect from You</strong></p>
                                <li>By way of the following table, we seek to inform you of the data we collect from you through the Platform, along with the specific identified purpose for the collection of such data. We will only require you to provide such personal data that is necessary for us to provide you with a Service at your request.</li>
                                <li>This will enable you to review our data collection practices, and provide us with your informed consent to the continuous collection, use and disclosure of your personal data, while offering our Platform to you.<br /><br />
                                    <table cellspacing="3" cellpadding="3" border={1} style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }} align="center">
                                        <thead></thead>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}><strong>Information We Collect</strong></td>
                                            <td width="319"><strong>Specified Purpose for Collection</strong></td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td colspan="2" width="811" ><strong><em>Information you provide to us</em></strong></td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>Name, Gender, Height, Weight, Languages Fluent and Spoken, Images, Phone Number, Date of Birth.</td>
                                            <td width="319">For the purposes of registration and creation of an account on the Platform. Verification of User age to ensure that our services are appropriately directed to all users.</td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>E-Mail Address, Residence Address, Phone Number.</td>
                                            <td width="319">Communicating with you regarding updates, events, including casting calls, call-backs, scheduled auditions, and submission of further information, where necessary.</td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>Professional Qualifications (Academic, Acting, Dancing), Biography, Previous Work Experience in films, advertisements, web series.</td>
                                            <td rowspan="4" style={{ borderColor: 'darkgrey', border: '0px', borderBottomWidth: 0, borderLeftWidth: 1 }}>For ascertaining your suitability to a casting notice, and review of your previous performances. </td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>Videos of user introduction, and any previous experience which they seek to share.</td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>Hyperlinks to third party video sharing platforms, which may host audition videos of the applicants.</td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>Your social media credentials for Instagram, Facebook, and any other social media credential which the user may wish to share.  </td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>Market survey responses, marketing preferences</td>
                                            <td width="319" >Personalization of Services and improvement of the Platform. </td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td colspan="2" width="811" ><strong><em>Information we collect about you from our Platform</em></strong></td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>Your activity on the Platform (pages you access, view)</td>
                                            <td width="319" >To remember your actions on the Platform, and identify you. </td>
                                        </tr>
                                        <tr style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderWidth: 1 }}>
                                            <td width="492" style={{ borderColor: 'darkgrey', borderCollapse: 'collapse', borderRightWidth: 1 }}>IP Address, Device details, User Location</td>
                                            <td width="319" >Minimize your efforts to interact with us and provide seamless personalized experience on your device.</td>
                                        </tr>
                                    </table>
                                    <p><strong>Data We Collect from Third Parties</strong></p></li>
                                <li><strong>Cookies.</strong> We collect information using cookies to help distinguish you from other users of our Platform, and offer you a personalized experience, and minimize your efforts with respect to your interactions with us. A cookie is a piece of data sent by the website to the user’s web browser while the user is browsing the website. Cookies are used to support critical functionality as well as track usage information that lets us improve our performance. In the event you wish to regulate the access of cookies to your information, you may change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; or You can set your browser to automatically not accept any cookies. You can manage cookies in the privacy settings of the web browser you are using. Please note that if you use your browser settings to block all cookies, you may not be able to access parts of our Platform and Services.</li>
                                <li>We may further work with a limited number of third parties (including mobile network providers, collection agencies, analytics providers) to provide the Services and may receive information about you from such third parties. You understand and agree that third party websites may have their own policies which are different from that of the Platform. You note that such Services, to that limited extent, will be governed by the policies of such third parties. You are encouraged to review the policies and services made available via these third party sites, before you consent to their usage on the Platform. </li>
                                <li>We do not collect any further data from you, if not specifically mentioned hereinabove. <br /><br />
                                    <p><strong>Legal Basis for Processing of your Data</strong></p>
                                </li>
                                <li>We process your personal data on the basis of your consent, which we will seek from you. Alternatively, we may further process your personal data, without your prior consent, for certain legitimate uses namely, and as permitted in law: <br />
                                    a.  For the specified purpose for which you have voluntarily provided your data – this may include submission of your details for casting notices/ calls; <br />
                                    b.  fulfilling an obligation to State instrumentalities to disclose processing data about a certain user;<br />
                                    c.  compliance with any judgment, decree under any law for the time being in force in India;<br />
                                    d.  Protect your or another person’s vital interests, involving a (i) threat to the life or immediate threat to the health of the individual, (ii) medical treatment or health services to any individual during an epidemic, outbreak of disease, or any other threat to public health; (iii) provide assistance during a disaster.
                                    <br /><br />
                                    <p><strong>Other Use Cases</strong></p>
                                </li>
                                <li>In addition to the foregoing, we may also use your data, in the following manner.  <br />
                                    a.  We need your data to create your account with us and allow you to continue on our Platform as a registered user;<br />
                                    b.  To provide and process service requests initiated by you;<br />
                                    c.  To market, advertise and promote the Platform and its Services;<br />
                                    d.  Provide you with a grievance redressal portal; <br />
                                    e.  To comply with any compliances required by the law enforcement agencies, governmental authorities; <br />
                                    f.  To provide you with news, special offers, general information about other products and services along with marketing information and surveys.
                                    <br /><br />
                                    <p><strong>Sharing your data with third parties</strong></p>
                                </li>
                                <li>We will never display, rent, or sell your personal data to others. We may share your data with selected third parties. For example, we may share your data with:<br />
                                    a.  A web development company which maintains our Platform. They will only access your data to address any issues you face on the Platform. <br />
                                    b.  Contact management systems, to send e-mails, social media messages and SMSes. <br /><br />
                                </li>
                                <li>There are certain other exceptional circumstances in which we may disclose your information to third parties. This would be where we believe that the disclosure is:<br />
                                    a.  Required by the law, or in order to comply with judicial proceedings, court orders or legal or regulatory proceedings;<br />
                                    b.  Necessary to protect the safety of our employees, our property, or the public;<br />
                                    c.  Necessary for the prevention or detection of crime, including exchanging information with other companies or organizations for the purposes of fraud protection and credit risk reduction;<br />
                                    d.  As part of a merger, business, or asset sale, in the event that this happens we will share your information with the prospective seller or buyer involved.<br /><br />
                                </li>
                                <li>We may also share aggregated, non-personal data publicly and with our partners, like publishers, advertisers, or connected sites to show trends about the general use of our Services.  <br /><br />
                                    <p><strong>Transfer of your data outside India</strong></p>
                                </li>
                                <li>Your data may be transferred to and stored in locations outside of the territory in which the Company is registered. We will do this only when the destination jurisdiction has an adequate and appropriate level of protection, and is not restricted in accordance with the applicable law of Republic of India. When we transfer your personal data from your home country to an alternate country, we will seek your express consent, and comply with our legal and regulatory obligations in relation to your personal data and putting appropriate safeguards in place to ensure an adequate level of protection for the personal data. </li>
                                <li>Our lawful basis for such transfer will be either on the basis of consent or one of the legitimate use cases permissible by laws.<br /><br />
                                    <p><strong>How long do we keep your personal data?</strong></p></li>
                                <li>Depending on the nature of the data you provide to us, and the context in which such data is furnished to us, we may have differential timelines. We keep data for at least as long as required/permitted by applicable law.  </li>
                                <li>We may further retain your data till the period that is required for the purposes of us meeting your requests on our Platform, and in compliance with the applicable laws, statutory requirements.  <br /><br />
                                    <p><strong>Duties of Data Principals</strong></p></li>
                                <li>Every user of this Platform must comply with the provisions of applicable laws for the time in force while exercising rights under this Policy. The users must ensure not to:  <br />
                                    •   impersonate another person;<br />
                                    •   suppress any material data while corresponding with us;<br />
                                    •   register a false or frivolous grievance or complaint with a Company; and<br />
                                    •   furnish which is not verifiably authentic, while exercising the right to correction or erasure under the applicable laws.
                                    <br /><br />
                                    <p><strong>Rights of Data Principals</strong></p>
                                </li>
                                <li>You have a number of rights in relation to the information we hold about you. In order to action these rights, you are encouraged to write to: contact@yrfcasting.com. These rights include:<br />
                                    •   The right to access the data we hold about you, and to obtain details of processing;<br />
                                    •   The right to withdraw your consent to our continued processing of the data, at any point of time, subject only to any legitimate reason for continued processing in certain circumstances; <br />
                                    •   The right to correct your account data and preferences available with us;<br />
                                    •   The right to erasure of any personal data that we process, in certain circumstances, to the extent permissible in law, and feasible.<br />
                                    •   The right to nominate an individual in the event of your death or incapacity to exercise your rights under this Privacy Policy.<br />
                                    •   The right to grievance redressal in respect of any act or omission on our part.
                                    <br /><br />
                                    Kindly ensure you use appropriate subjects to mention the right (e.g., data correction or consent withdrawal) while raising all such requests, this would help us process your requests in a faster and more efficient manner. We may take 30 days from the date of receipt of a request to complete your requests. <br /><br />
                                    <p><strong>How do we protect your personal data?</strong></p>
                                </li>
                                <li>We understand that we must safeguard your personal data available with us. We use and employ industry standard technical, organizational, logical, physical, administrative measures to protect your personal information against any unauthorized access, modification, or use. </li>
                                <li>We employ necessary anonymization techniques to ensure that your personal identity is never visible to our systems, or our teams. Unfortunately, the transmission of data via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the complete security of your data transmitted via our Platform. <br /><br />
                                    <p><strong>Personal Data of Children</strong></p>
                                </li>
                                <li>Our services are not directed at minors. We do not knowingly permit any person who is under 18 (eighteen) years of age to register with the services or to provide their personal data. If we become aware that any personal data of persons less than 18 (eighteen) years of age has been collected on the website without verifiable parental consent, then we will take the appropriate steps to delete any such data and notify the parent. </li>
                                <li>Should a parent or guardian have reasons to believe that a minor has provided us with personal data without their verifiable consent, please contact us at: contact@yrfcasting.com to request the deletion of your child's data from the Platform.<br /><br /></li>
                                <p><strong>Changes to our Privacy Policy</strong></p>
                                <li>We make periodical changes to the privacy policy with or without notice. The new terms would be displayed on the webpage and by using or continuing to use the Services you agree to the periodical changes made to the Privacy Policy and it would also be presumed that you are aware of any modifications thus made. Please check back frequently to see any updates or changes to our privacy policy.<br /><br />
                                    This version was last updated on 27 September 2023 and archived versions can be obtained by contacting us.<br /><br />
                                    <p><strong>Contact Us</strong></p>
                                </li>
                                <li>If you have any questions about your privacy or our use of your personal data, please contact our Grievance Officer: Sachin Kedar at sachink@yashrajfilms.com.<br /><br /></li>
                            </ol>
                            <p style={{ fontWeight: 'bold' }}>If you wish to avail of our Services, and are satisfied with our data handling practices, you hereby consent to the processing of your personal data.<br /><br /></p>
                        </main>
                        <footer style={{
                            backgroundColor: '#333',
                            color: '#fff',
                            textAlign: 'center',
                            padding: '10px',
                            position: 'relative',
                            bottom: 0,
                            width: ' 100%',
                            display: 'flex'
                        }}>
                            <div style={{ flex: 0.8, justifyContent: 'flex-end', display: 'flex' }}>

                                <p>&copy; Yash Raj Films Pvt. Ltd.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="" onClick={() => navigate('/')}>Home</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href='' onClick={() => navigate("/termsofservice")}>Terms & Conditions</a></p>
                            </div>
                            <div style={{ flex: 0.5, textAlign: 'center', justifyContent: 'flex-end', display: 'flex' }}>
                                <p style={{ paddingRight: 20 }}>Powered by IFI Techsolutions
                                </p>
                            </div>
                        </footer>
                    </body>

                </div>
            </>

        </DocumentTitle>

    )
}

export default PrivacyPolicy;