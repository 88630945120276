import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, CustomSnackBar, SimpleCard } from 'app/components';
import { Autocomplete, Box, Button, Checkbox, CircularProgress, DialogContent, Divider, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Radio, RadioGroup, Snackbar, Stack, styled, Typography, useMediaQuery, } from '@mui/material';
// import Loading from 'app/components/MatxLoading';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { endPointName } from 'apis/apiList';
import { CallAPI, CallAPISasUploadVideo, CallAPIUploadPic } from 'apis/callAPI';
// import { Description } from '@mui/icons-material';
import moment from 'moment/moment';
import MuiAlert from '@mui/material/Alert';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Span } from 'app/components/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
// import { amber, green } from '@mui/material/colors';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../MatxLoading';
import CloseIcon from '@mui/icons-material/Close';
import { catchError } from 'app/firebase/Crashlytics';
import DocumentTitle from 'react-document-title';
import { NumberValidate } from 'utils/utilities';
import { yrfGender } from 'app/constant';
import useAuth from 'app/hooks/useAuth';
import ServerErrorModal from '../ServerErrorModal'
import { sasToken } from 'authConfig';
import db from 'app/db/database';
import dayjs from 'dayjs';

const TextField = styled(TextValidator)(() => ({
    width: "100%",
    marginBottom: 16,
}));

const CreateUpdatePost = ({ use, postId, onClick, onSubmit, validTillExp }) => {
    const { logout, user } = useAuth();
    const userFromSession = JSON.parse(sessionStorage.getItem("userJSON"));
    const accessToken = window.localStorage.getItem('accessToken')
    const fileUploadRef = useRef();
    const videoUploadRef = useRef();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [fromAge, setAgeFrom] = useState("");
    const [toAge, setToAge] = useState("");
    const [snackOpen, setSnackOpen] = useState(false);
    const [PostId, setPostId] = useState(0);
    const [publishOn, setPublishOn] = useState(null);
    const [validTill, setvalidTill] = useState(null);
    const [cities, setCities] = useState([]);
    const [States, setStates] = useState([]);
    const [userGenderValue, setUserGenderValue] = useState();
    const [UserLanguageValue, setUserLanguageValue] = useState([]);
    const [UserCityValue, setUserCityValue] = useState([]);
    // const [allUsers, setAllUsers] = useState([]);
    const [userStateValue, setUserStateValue] = useState([]);
    const [userCategoryValue, setUserCategoryValue] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [languagesList, setLanguagesList] = useState([]);
    const [snackBarMessage, setSnackBarMessage] = useState("");
    const [isActive, setIsActive] = useState(false);
    const [allCities, setAllCities] = useState([]);
    const [allStatesCity, setAllStatesCity] = useState([]);
    const [postData, setPostData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [popUp, setPopUp] = useState(false);
    const [popUpMessage, setPopUpMessage] = useState('');
    const [popUpDesc, setPopUpDesc] = useState('');
    const [endDate, setEndDate] = useState('');
    const [popUpSubmit, setPopUpSubmit] = useState(false);
    const [APILoading, setAPILoading] = useState(false);
    const [onApiLoading, setOnApiLoading] = useState(false);
    const [projectSelected, setProjectSelected] = useState(null);
    const [projectList, setProjectList] = useState([]);
    const [charSelected, setCharSelected] = useState(null);
    const [characterData, setCharacterData] = useState([]);
    const [radioValue, setRadioValue] = useState(1);
    const [uploadFile, setUploadFile] = useState("");
    const [fileFlag, setFileFlag] = useState(false);
    const [uploadVideoFile, setUploadVideoFile] = useState("");
    const [videoFileFlag, setVideoFileFlag] = useState(false);
    const [videoFileURL, setVideoFileURL] = useState('');
    const [uploadSelectedVideoFile, setUploadSelectedVideoFile] = useState([]);
    const [openValidTill, setOpenValidTill] = useState(false);
    const [openPublishOn, setOpenPublishOn] = useState(false);
    const [fileURL, setFileURL] = useState([]);
    const [fileVideoUrl, setFileVideoURL] = useState([]);
    const [uploadSelectedFile, setUploadSelectedFile] = useState([]);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isOnline, setOnline] = useState(true);
    const [IsFileError, setIsFileError] = useState(false);
    const [allCountries, setAllCountries] = useState([]);
    const [userCountryValue, setUserCountryValue] = useState([]);
    const controller = new AbortController();
    const signal = controller.signal;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const navigate = useNavigate();
    const gender = [
        {
            label: "Both",
            value: 2
        },
        {
            label: "Female",
            value: 1
        },
        {
            label: 'Male',
            value: 0
        }
    ];

    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
        controller.abort()
    });


    const getStates = async () => {
        try {
            const states = await db.state.toArray();
            // let countryData = states.map(el => ({ label: el.state, value: el.stateId }))
            setAllStatesCity(states);
            getPostDetails(states)
            // let response = await CallAPI(endPointName.state, "get");
            // if (response !== 'error') {
            //     let statesCities = response.cityStateModels;
            //     let onlyStates = response.cityStateModels.filter(el => el.stateId && el.isStateActive == true)
            //     setAllStatesCity(statesCities);
            //     let temp = []
            //     for (let i = 0; i < onlyStates.length; i++) {
            //         if (temp.length == 0) {
            //             temp.push(onlyStates[i]);
            //         } else {
            //             let objState = temp.findIndex(el => el.stateId == onlyStates[i].stateId);
            //             if (objState == -1) {
            //                 temp.push(onlyStates[i]);
            //             }
            //         }
            //     }
            //     let temp1 = temp.map(el => ({ label: el.state, value: el.stateId }))
            //     if (use == 'update') {
            //         getPostDetails(statesCities)
            //     } else {
            //         getCities();
            //     }
            //     setStates(temp1);
            // } else {
            //     setErrorMsg('Server Error')
            //     setErrorPopUp(true)
            // }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_1");
        }
    }

    const getCities = async () => {
        try {

            const cities = await db.city.toArray();
            let cityData = cities.map(el => ({ label: el.city, value: el.cityId }))
            setAllCities(cityData);
            return cities;

            // let cities = await CallAPI(endPointName.CityData, "get");
            // if (cities !== 'error') {

            //     let temp = cities.map((el, index) => ({ label: el.city, value: el.cityId, stateId: el.stateId, key: index }))
            //     // let nonDuplicateCities()
            //     setAllCities(temp);
            //     // setCities(temp);
            // return cities;
            // } else {
            //     setErrorMsg('Server Error')
            //     setErrorPopUp(true)
            // }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_2");
        }
    }

    // const getCountry = async () => {
    //     try {
    //         let response = await CallAPI(endPointName.Country, "get");
    //         if (response) {
    //             let countryData = response.map(el => ({ label: el.country, value: el.countryId }))
    //             setAllCountries(countryData);
    //             // let onlyStates = response.cityStateModels.filter(el => el.stateId && el.isStateActive == true)
    //             // let temp = []
    //             // for (let i = 0; i < onlyStates.length; i++) {
    //             //     if (temp.length == 0) {
    //             //         temp.push(onlyStates[i]);
    //             //     } else {
    //             //         let objState = temp.findIndex(el => el.stateId == onlyStates[i].stateId);
    //             //         if (objState == -1) {
    //             //             temp.push(onlyStates[i]);
    //             //         }
    //             //     }
    //             // }
    //             // let temp1 = temp.map(el => ({ label: el.state, value: el.stateId }))
    //             // setStates(temp1);
    //         } else {
    //             setErrorMsg('Server Error')
    //             setErrorPopUp(true)
    //         }
    //     } catch (error) {
    //         catchError(error, "UserList_1")
    //     }
    // }

    const getCountries = async () => {
        try {
            const countries = await db.countries.toArray();
            let countryData = countries.map(el => ({ label: el.country, value: el.countryId }))
            setAllCountries(countryData);
        } catch (error) {
            catchError(error, "Error fetching countries Create Update Post:");
        }
    };

    const getLanguages = async () => {
        try {
            let response = await CallAPI(endPointName.Language, "get");
            if (response !== 'error') {
                setLanguagesList(response);
            } else {
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_3");
        }
    }

    const getCategory = async () => {
        try {
            const categoryResponse = await CallAPI(endPointName.GetWorkExpCategoryMaster, "get");
            if (categoryResponse !== 'error') {

                setCategoryList(categoryResponse);
                // {
                //     use == 'update' &&
                //         setAPILoading(false);
                // }
            } else {
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_4");
        }
    }
    const onSubmitPost = async (param, param1) => {
        try {
            if (isOnline) {

                const accessTokenCheck = window.localStorage.getItem('accessToken')
                if (accessTokenCheck !== null) {
                    if (parseInt(fromAge) <= parseInt(toAge)) {
                        setOnApiLoading(true);
                        let orgIdFromSession = JSON.parse(sessionStorage.getItem("userJSON"));

                        if (uploadSelectedFile.length === 0 && radioValue === '2') {
                            setIsFileError(true)
                        } else {
                            setIsFileError(false)
                        }

                        let fileToUpload = []
                        if (fileFlag == true) {
                            for (let i = 0; i < uploadSelectedFile.length; i++) {
                                let source = new FormData();
                                setAPILoading(true);
                                source.append('name', uploadSelectedFile[i].name);
                                source.append('file', uploadSelectedFile[i]);
                                let fileUrl = await CallAPIUploadPic(endPointName.FileUpload + '4', source);
                                // fileToUpload = fileUrl.uploadDocURL;
                                fileToUpload.push(fileUrl.uploadDocURL)
                            }
                        }

                        let tempVideo = []
                        if (videoFileFlag === true) {
                            for (let i = 0; i < uploadSelectedVideoFile.length; i++) {
                                let fileUrl = await CallAPISasUploadVideo(sasToken, uploadSelectedVideoFile[i], signal);
                                let dataUrl = fileUrl.url.split(`${sasToken}`);
                                tempVideo.push(dataUrl[0])
                            }
                        }

                        /** End code for file upload */
                        const payload = {
                            Param: param,
                            PostId: PostId,
                            Title: title,
                            AgeFrom: fromAge,
                            AgeTo: toAge,
                            CharacterId: charSelected.characterId,
                            filesAttachment: fileFlag == true ? fileToUpload : [],
                            videosAttachment: videoFileFlag == true ? tempVideo : [],
                            AuditionType: radioValue,
                            Gender: userGenderValue ? userGenderValue.value : userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1 ? "3" : "2",
                            States: userStateValue.length == 0 ? ["0"] : userStateValue.map(el => el.value),
                            Cities: UserCityValue.length == 0 ? ["0"] : UserCityValue.map(el => el.value),
                            Countries: userCountryValue.length == 0 ? ["0"] : userCountryValue.map(el => el.value),
                            Description: description,
                            PublishOn: param === 'Insert' ? moment(publishOn.$d).format("YYYY-MM-DD") : moment(publishOn.$d).format("YYYY-MM-DD"),
                            ValidTill: param === 'Insert' ? moment(validTill.$d).format("YYYY-MM-DD") : moment(validTill).format("YYYY-MM-DD"),
                            OrganizationId: orgIdFromSession.organizationId,
                            Languages: UserLanguageValue.length == 0 ? ["0"] : UserLanguageValue.map(el => el.languageId),
                            // Categories: userCategoryValue.length == 0 ? ["0"] : userCategoryValue.map(el => el.workExpCategoryId),
                            isActive: 1
                        }
                        const AddEditPostResponse = await CallAPI(endPointName.AddEditPost, "post", payload);
                        if (AddEditPostResponse.statusCode == 200) {
                            setOnApiLoading(false);
                            if (use == 'create') {
                                // setAPILoading(true);
                                setPopUp(true)
                                setPopUpMessage('Success!')
                                setPopUpSubmit(true)
                            } else {
                                setAPILoading(true);
                            }
                            if (use == 'create') {
                                // setSnackBarMessage('Audition Created Successfully')
                                // setSnackOpen(true);
                                setOnApiLoading(false);
                                setTitle("");
                                setDescription('');
                                setAgeFrom('');
                                setToAge("");
                                setUserStateValue([]);
                                setUserCategoryValue([]);
                                setUserCityValue([]);
                                setUserGenderValue();
                                setUserLanguageValue([]);
                                setPublishOn(null);
                                setProjectSelected(null);
                                setCharSelected(null)
                                setvalidTill(null);
                                setAPILoading(false);
                            } else if (use == 'update') {
                                setSnackBarMessage('Audition updated successfully')
                                setOnApiLoading(false);
                                setSnackOpen(true);
                                // onClick();
                                onSubmit();
                            }
                        } else if (AddEditPostResponse.statusCode === 201) {
                            setOnApiLoading(false);
                            setErrorMsg('Already Exist Audition')
                            setErrorPopUp(true)
                        } else {
                            setOnApiLoading(false);
                            setSnackOpen(true);
                            setSnackBarMessage("Server Error");
                        }
                        // } else {
                        //     alert("Server Error in uploading image");
                        // }
                    } else {
                        setSnackBarMessage("Enter Valid Age");
                        setSnackOpen(true);
                    }
                } else {
                    logout()
                }
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_5");
        }
    }

    const handleCloseSnackBar = (_, reason) => {
        try {
            if (reason === "clickaway") {
                return;
            }
            setSnackOpen(false);
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_6");
        }
    }

    const getPostDetails = async (allStatesCity) => {
        try {
            setAPILoading(true);
            const dataProj = await getProjectDetails();
            const payload = {
                postId: postId
            }
            let responsePostDetails = await CallAPI(endPointName.getPostDetails, "POST", payload);
            // let source = new FormData();
            // source.get('name', uploadSelectedFile.name);
            if (responsePostDetails !== 'error') {
                setPostId(responsePostDetails.postId)
                setPostData(responsePostDetails);
                setTitle(responsePostDetails.title);
                setAgeFrom(responsePostDetails.ageFrom === 0 ? '' : responsePostDetails.ageFrom);
                setToAge(responsePostDetails.ageTo === 0 ? '' : responsePostDetails.ageTo);
                if (responsePostDetails.filesAttachment.length > 0) {
                    setUploadSelectedFile(responsePostDetails.filesAttachment)
                }
                if (responsePostDetails.videosAttachment.length > 0) {
                    setUploadSelectedVideoFile(responsePostDetails.videosAttachment)
                }
                setFileURL(responsePostDetails.filesAttachment)
                setFileVideoURL(responsePostDetails.videosAttachment)
                setUserLanguageValue(responsePostDetails.language);
                setUserCategoryValue(responsePostDetails.category);
                setRadioValue(responsePostDetails.auditionType);
                setPublishOn(responsePostDetails.publishOn);
                if (validTillExp) {
                    setvalidTill(null);
                } else {
                    setvalidTill(responsePostDetails.validTill);
                }
                setDescription(responsePostDetails.description);
                let tempProject = dataProj.filter((el) => el.projectId == responsePostDetails.projectId)
                if (use == 'update' && tempProject) {
                    if (moment(new Date()).format("YYYY-MM-DD") < moment(tempProject[0].endDate).format("YYYY-MM-DD")) {
                        setEndDate(tempProject[0].endDate)
                    }
                }
                setCharacterData(tempProject[0].characterData);
                setProjectSelected(tempProject[0]);
                let tempCharacter = tempProject[0].characterData.filter((el) => el.characterId == responsePostDetails.characterId)
                setCharSelected(tempCharacter[0]);
                setUserCountryValue(responsePostDetails.countries.map(el => ({ label: el.country, value: el.countryId })))
                setUserStateValue(responsePostDetails.state.map(el => ({ label: el.state, value: el.stateId })))
                let getAllCities = await getCities()
                let cityData = responsePostDetails.city.filter(element => {
                    let filterAllCities = getAllCities.filter(el => {
                        setAPILoading(true);
                        if (element.cityId == el.cityId) {
                            element.stateId = el.stateId
                        }
                    })
                })
                setUserCityValue(responsePostDetails.city.map(el => ({ label: el.city, value: el.cityId, stateId: el.stateId })))
                if (userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) {
                    let genderVal = yrfGender.filter((el) => el.value == responsePostDetails.gender);
                    setUserGenderValue(genderVal[0]);
                } else {
                    let genderVal = gender.filter((el) => el.value == responsePostDetails.gender);
                    setUserGenderValue(genderVal[0]);
                }
                if (use == 'update' && responsePostDetails.state.length == 0) {
                    getCities();
                } else {
                    getCities();
                }
                const filteredCities1 = [];
                const filteredCities = responsePostDetails.state.map(el => {
                    let cities = allStatesCity.filter(element => element.stateId == el.stateId)
                    filteredCities1.push(cities);
                });
                if (filteredCities.length > 0) {
                    let cities = filteredCities1[0].map(el => ({ label: el.city, value: el.cityId }))
                    setCities(cities);
                }
            } else {
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
            setAPILoading(false);
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_7");
        }
    }
    const getProjectDetails = async () => {
        try {
            let userFromSession = JSON.parse(sessionStorage.getItem("userJSON"));
            const payload = {
                OrganizationId: userFromSession.organizationId
            }
            let responseProjectDetails = await CallAPI(endPointName.AllProjectData, "POST", payload);
            if (responseProjectDetails) {
                setProjectList(responseProjectDetails)
            } else {
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
            return responseProjectDetails
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_8");
        }
    }

    useEffect(() => {
        if (accessToken !== null) {
            if (isOnline) {
                {
                    use == 'update' &&
                        setAPILoading(true);
                }
                getProjectDetails();
                // getCountry();
                getCountries();
                getStates();
                { use == 'insert' && getCities() }
                getLanguages();
                getCategory();
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } else {
            logout()
        }
    }, [accessToken]);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const handleChange = (event) => {
        try {
            setRadioValue(event.target.value);
            setUploadFile("")
            setUploadVideoFile("")
            setUploadSelectedFile([])
            setUploadSelectedVideoFile([]);
            setFileFlag(false);
            setVideoFileFlag(false)
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_9");
        }
    };

    const onClosePopUp = () => {
        setPopUp(false);
    }

    const onChangeProject = (el) => {
        try {
            if (el == null) {
                setCharSelected(null);
                setProjectSelected(null);
                setEndDate('');
            } else {
                if (moment(new Date()).format("YYYY-MM-DD") > moment(el.endDate).format("YYYY-MM-DD")) {
                    setPopUp(true)
                    setPopUpMessage('Project selection can not be done.')
                    setPopUpDesc('Since this project has expired.')
                } else {
                    setProjectSelected(el);
                    setEndDate(el.endDate);
                    setvalidTill(null);
                    setCharSelected(null)
                    setCharacterData(el !== null ? el.characterData : [])
                }
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_10");
        }
    }

    const onChangeCharacter = (el) => {
        try {
            if (el !== null) {
                if (el.userId && el.userName) {
                    setPopUp(true)
                    setPopUpMessage('Actor selection is not possible.')
                    setPopUpDesc('Since an actor has already been selected for this role.')
                } else {
                    setCharSelected(el);
                }
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_11");
        }
    }

    const fileChangedHandler = (event) => {
        try {
            if (event.target.files.length > 0) {
                let fileToUpload = []
                for (let i = 0; i < event.target.files.length; i++) {
                    let fileSize = event.target.files[i].size
                    if (fileSize > 5000000) {
                        setUploadFile('')
                        setUploadSelectedFile([])
                        setSnackBarMessage("Please upload a file smaller than 5 MB")
                        setSnackOpen(true);
                    } else {
                        if (event.target.value.length !== 0) {
                            setUploadFile(event.target.value)
                            // setUploadSelectedFile(event.target.files)
                            // setUploadSelectedFile(event.target.files[i])
                            fileToUpload.push(event.target.files[i])
                            setFileFlag(true);
                        } else {
                            setUploadFile(event.target.value)
                        }
                    }
                }
                setUploadSelectedFile(fileToUpload)
            } else {
                setUploadFile('')
                setUploadSelectedFile('')
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_12");
        }
    }

    const fileChangedHandlerVideo = (event) => {
        try {
            if (event.target.files.length > 0) {
                let fileToUpload = []
                for (let i = 0; i < event.target.files.length; i++) {

                    if (event.target.value.length !== 0) {
                        setUploadVideoFile(event.target.value)
                        fileToUpload.push(event.target.files[i])
                        setVideoFileFlag(true);
                    } else {
                        setUploadVideoFile(event.target.value)
                    }

                }
                setUploadSelectedVideoFile(fileToUpload)
            } else {
                setUploadVideoFile('')
                setUploadSelectedVideoFile('')
            }
        } catch (error) {
            setAPILoading(false);
            catchError(error, "CreateUpdatePost_12");
        }
    }

    const handleTextFieldClick = (type) => {
        try {
            if (type === 'publishOn') {
                setOpenPublishOn(true);
            } else {
                setOpenValidTill(true);
            }
        } catch (error) {
            catchError(error, "CreateUpdatePost_13");
        }
    };

    const handleServerError = () => {
        try {
            setErrorPopUp(false)
            setErrorMsg('')
        } catch (error) {
            catchError(error, "CreateUpdatePost_14")
        }
    }

    const onChangeCountry = (el) => {
        try {
            if (el.length === 0) {
                setUserCountryValue(null);
            } else {
                setUserCountryValue(el);
                let stateFilter = allStatesCity.filter(elm => elm.countryId == el[el.length - 1].value && elm.isStateActive == true);
                let uniqueList = stateFilter.filter((elem, i, a) => a.findIndex(item => (item.stateId === elem.stateId)) === i)
                let mapState = uniqueList.map((el) => ({ label: el.state, value: el.stateId, countryId: el.countryId }))
                if (el.length == 1) {
                    setStates(mapState);
                } else {
                    const temp2 = States.concat(mapState);
                    setStates(temp2);
                }
            }
        } catch (error) {
            catchError(error, "CreateUpdatePost_15")
        }
    }

    return (
        <DocumentTitle title={'Create New Audition'}>

            <div style={{ margin: use == 'create' ? 30 : 0 }}>
                <Stack spacing={2}>
                    {use == 'create' &&
                        <Box className="breadcrumb">
                            <Breadcrumb routeSegments={[{ name: 'Create New Audition', namePage: 'Auditions' }]} />
                        </Box>
                    }
                    {use == 'create' ?
                        <>
                            <SimpleCard>
                                <ValidatorForm onSubmit={() => onSubmitPost(use == 'create' ? "Insert" : "Update")} onError={() => null} >
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
                                        <Grid item xs={4} >
                                            <Autocomplete
                                                options={projectList}
                                                size='medium'
                                                getOptionLabel={(option) => option.projectName}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <Typography >{option.projectName}</Typography> {moment(new Date()).format("YYYY-MM-DD") > moment(option.endDate).format("YYYY-MM-DD") && <Typography style={{ color: 'red', fontSize: 10, opacity: 0.8, fontStyle: 'italic' }}>Expired: {moment(option.endDate).format("DD-MM-YYYY")}</Typography>}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, tabIndex: 1 }}
                                                        variant="outlined"
                                                        label="Select Project*"
                                                        value={projectSelected}
                                                        fullWidth
                                                        validators={["required"]}
                                                        errorMessages={["Project is required"]}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                )}
                                                value={projectSelected}
                                                onChange={(_, newValue) => {
                                                    onChangeProject(newValue);
                                                    // setProjectSelected(newValue);
                                                    // setCharacterData(newValue.characterData)
                                                }}
                                            />

                                        </Grid>
                                        <Grid item xs={4} style={{ marginTop: isNonMobile == false && -20 }}>
                                            <Autocomplete
                                                options={characterData}
                                                size='medium'
                                                getOptionLabel={(option) => option.characterName}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props}>
                                                        <Typography style={{ color: option.userName !== '' ? 'green' : 'black' }}>{option.characterName}</Typography>
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, tabIndex: 2 }}
                                                        variant="outlined"
                                                        label="Select Character*"
                                                        value={charSelected}
                                                        fullWidth
                                                        autoComplete="off"
                                                        validators={["required"]}
                                                        errorMessages={["Character is required"]}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                )}
                                                value={charSelected}
                                                onChange={(_, newValue) => {
                                                    onChangeCharacter(newValue)
                                                    // setCharSelected(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} style={{ marginTop: isNonMobile == false && -30, marginBottom: isNonMobile == false && -10 }}>
                                            <FormControl style={{ marginBottom: 21, marginTop: 6 }}>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    // defaultValue="onSite"
                                                    name="radio-buttons-group"
                                                    row={isNonMobile ? true : false}
                                                    size='medium'
                                                    value={radioValue}
                                                    onChange={handleChange}
                                                >
                                                    <Typography style={{ textAlign: 'center', marginTop: 10, marginRight: 10 }}>Audition Type :</Typography>
                                                    <FormControlLabel value={1} control={<Radio tabIndex={3} />} label="On Site" size='medium' />
                                                    <FormControlLabel value={2} control={<Radio tabIndex={4} />} label="Online" size='medium' />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4} md={4} style={{ marginTop: -20 }}>
                                            <TextField
                                                label="Title*"
                                                size='medium'
                                                value={title}
                                                inputProps={{ tabIndex: 5 }}
                                                autoComplete="off"
                                                onChange={(event) =>
                                                    setTitle(event.target.value)
                                                }
                                                // style={{ marginBottom: 16 }}
                                                fullWidth
                                                validators={["required"]}
                                                errorMessages={["Title is required"]}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} style={{ marginTop: -20 }}>
                                            <Stack spacing={2} direction="row" >
                                                <TextField
                                                    label="From Age*"
                                                    size='medium'
                                                    value={fromAge}
                                                    maxLength={5}
                                                    inputProps={{ tabIndex: 6 }}
                                                    onChange={(event) => {
                                                        const test = NumberValidate(event.target.value)
                                                        if (event.target.value.length <= 3) {
                                                            if (test || event.target.value == "") {
                                                                setAgeFrom(event.target.value)
                                                            }
                                                        }
                                                    }}
                                                    validators={["required"]}
                                                    autoComplete="off"
                                                    errorMessages={["From Age is required"]}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                                <p style={{ paddingTop: 15 }}>To</p>
                                                <TextField
                                                    label="To Age*"
                                                    size='medium'
                                                    value={toAge}
                                                    inputProps={{ tabIndex: 7 }}
                                                    autoComplete="off"
                                                    onChange={(event) => {
                                                        const test = NumberValidate(event.target.value)
                                                        if (event.target.value.length <= 3) {
                                                            if (test || event.target.value == "") {
                                                                setToAge(event.target.value)
                                                            }
                                                        }
                                                    }}
                                                    validators={["required"]}
                                                    errorMessages={["To Age is required"]}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={4} md={4} style={{ marginTop: -20 }}>
                                            <Autocomplete
                                                // multiple
                                                // id="tags-outlined"
                                                options={userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1 ? yrfGender : gender}
                                                size='medium'
                                                getOptionLabel={(option) => option.label}
                                                isOptionEqualToValue={(option, value) => option.value == value.value}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        inputProps={{ ...params.inputProps, tabIndex: 8 }}
                                                        label="Select Gender*"
                                                        value={userGenderValue}
                                                        fullWidth
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        validators={["required"]}
                                                        errorMessages={["Gender is required"]}
                                                    />
                                                )}
                                                // defaultValue={userFromSession.organizationId === 4 ? yrfGender[0] : gender[0]}
                                                value={userGenderValue}
                                                // style={{ marginBottom: 16 }}
                                                onChange={(_, newValue) => {
                                                    setUserGenderValue(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} style={{ marginTop: -20 }}>
                                            <Autocomplete
                                                multiple
                                                size='medium'
                                                id="tags-outlined-countries"
                                                options={allCountries}
                                                getOptionLabel={(option) => option.label}
                                                isOptionEqualToValue={(option, value) => option.value == value.value}
                                                // renderOption={(props, option) => {
                                                //     return (
                                                //         <li {...props} key={option.value}>
                                                //             {option.label}
                                                //         </li>
                                                //     );
                                                // }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, tabIndex: 9 }}
                                                        variant="outlined"
                                                        label="Select Country"
                                                        fullWidth
                                                        value={userCountryValue}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                )}
                                                value={userCountryValue}
                                                // style={{ marginBottom: 16 }}
                                                onChange={(_, newValue) => {
                                                    if (newValue.length == 0) {
                                                        setUserCountryValue(newValue);
                                                        setStates([])
                                                        setCities([])
                                                        setUserStateValue([])
                                                        setUserCityValue([])
                                                    } else {
                                                        onChangeCountry(newValue)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: -20 }}>
                                            <Autocomplete
                                                multiple
                                                size='medium'
                                                id="tags-outlined"
                                                options={States}
                                                getOptionLabel={(option) => option.label}
                                                // renderOption={(props, option) => {
                                                //     return (
                                                //         <li {...props} key={option.value}>
                                                //             {option.label}
                                                //         </li>
                                                //     );
                                                // }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, tabIndex: 10 }}
                                                        variant="outlined"
                                                        label="Select State"
                                                        fullWidth
                                                        value={userStateValue}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                )}
                                                value={userStateValue}
                                                // style={{ marginBottom: 16 }}
                                                onChange={(_, newValue) => {
                                                    setUserStateValue(newValue);
                                                    if (newValue.length == 0) {
                                                        // setCities(allCities)
                                                        setCities([])
                                                        setUserCityValue([])
                                                        // if (UserCityValue.length > 0) {
                                                        //     console.log('if if-----')
                                                        //     setUserCityValue([]);
                                                        // }
                                                    } else {
                                                        let filterStateVal = newValue.filter((el) => {
                                                            // let tempCity = UserCityValue.filter((element) => {
                                                            //     if (element.stateId == el.value) {
                                                            //         return element;
                                                            //     } 
                                                            // })
                                                            // if (tempCity.length !== 0) {
                                                            //     console.log('-------474-----');
                                                            //     setUserCityValue(tempCity)
                                                            // }
                                                        })
                                                        // if (UserCityValue.length > 0) {
                                                        //     console.log('else if -----', UserCityValue)
                                                        // let filtereCityChange = UserCityValue.filter((el)=>el.)
                                                        // setUserCityValue([]);
                                                        // setCities(allCities);
                                                        // } else {
                                                        let cityFilter = allStatesCity.filter((el) => el.stateId == newValue[newValue.length - 1].value);
                                                        let temp = cityFilter.map(el => ({ label: el.city, value: el.cityId, stateId: el.stateId }))
                                                        if (newValue.length == 1) {
                                                            setCities(temp);
                                                        } else {
                                                            const temp2 = cities.concat(temp);
                                                            setCities(temp2);
                                                        }
                                                        // }
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: -20 }}>
                                            <Autocomplete
                                                multiple
                                                size='medium'
                                                id="tags-outlined"
                                                options={userStateValue.length == 0 ? allCities : cities}
                                                renderOption={(props, option) => {
                                                    return (
                                                        <li {...props} key={option.value}>
                                                            {option.label}
                                                        </li>
                                                    );
                                                }}
                                                // isOptionEqualToValue={(option, value) => option.stateId == value.stateId}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, tabIndex: 11 }}
                                                        variant="outlined"
                                                        label="Select City"
                                                        value={UserCityValue}
                                                        fullWidth
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                )}
                                                value={UserCityValue}
                                                // style={{ marginTop: 16, marginBottom: 16 }}
                                                onChange={(_, newValue) => {
                                                    setUserCityValue(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: -20 }}>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                size='medium'
                                                options={languagesList}
                                                getOptionLabel={(option) => option.languageName}
                                                isOptionEqualToValue={(option, value) => option.languageId == value.languageId}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{ ...params.inputProps, tabIndex: 12 }}
                                                        variant="outlined"
                                                        label="Select Language"
                                                        fullWidth
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                )}
                                                // style={{ marginBottom: 16 }}
                                                value={UserLanguageValue}
                                                onChange={(_, newValue) => {
                                                    setUserLanguageValue(newValue);
                                                }}
                                            />
                                            <Stack spacing={2} direction="row" style={{ marginTop: 5 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Publish On*"
                                                        size='medium'
                                                        value={publishOn}
                                                        onChange={(newValue) => {
                                                            if (newValue) {
                                                                if (newValue.$d == "Invalid Date") {
                                                                    setvalidTill(null);
                                                                }
                                                                setPublishOn(newValue);
                                                            }
                                                        }}
                                                        open={openPublishOn}
                                                        onOpen={() => setOpenPublishOn(true)}
                                                        onClose={() => setOpenPublishOn(false)}
                                                        disablePast
                                                        format="DD-MM-YYYY"
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                size='medium'
                                                                inputProps={{ ...params.inputProps, tabIndex: 13, readOnly: true }}
                                                                value={publishOn}
                                                                autoComplete="off"
                                                                onClick={() => handleTextFieldClick('publishOn')}
                                                                validators={["required"]}
                                                                errorMessages={["Publish On is required"]}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />}
                                                    // minDate={new Date()}
                                                    />
                                                    <DatePicker
                                                        label={"Valid Till*"}
                                                        value={validTill}
                                                        size='medium'
                                                        minDate={dayjs(publishOn)}
                                                        maxDate={dayjs(endDate)}
                                                        open={openValidTill}
                                                        onOpen={() => setOpenValidTill(true)}
                                                        onClose={() => setOpenValidTill(false)}
                                                        onChange={(newValue) => {
                                                            if (newValue) {
                                                                setvalidTill(newValue);
                                                            }
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                inputProps={{ ...params.inputProps, tabIndex: 14, readOnly: true }}
                                                                size='medium'
                                                                value={dayjs(validTill)}
                                                                onClick={() => handleTextFieldClick('validTill')}
                                                                autoComplete="off"
                                                                validators={["required"]}
                                                                errorMessages={["Valid Till is required"]}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />}
                                                        format="DD-MM-YYYY"
                                                        disabled={publishOn == "Invalid Date" ? true : false}
                                                    />

                                                </LocalizationProvider>
                                            </Stack>
                                        </Grid>

                                        {/* {(radioValue == 2 || userFromSession.organizationId === 6) && */}
                                        {/* {radioValue == 2 &&
                                            <> */}
                                        <Grid item xs={4} sm={2} md={4} lg={4} style={{ marginTop: -20, marginBottom: 20 }}>
                                            {radioValue === '2' ?
                                                <TextField
                                                    style={{ display: 'block', height: 25, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold' }}
                                                    onClick={() => {
                                                        console.log("upload ref ==> ", fileUploadRef.current)
                                                        document.getElementById('getFile').click()
                                                    }
                                                    }
                                                    placeholder="Upload Script File*"
                                                    disabled
                                                    fullWidth={true}
                                                    value={uploadSelectedFile.length > 0 ? uploadSelectedFile.length + ' Files selected' : null}
                                                    // validators={["required"]}
                                                    // error={IsFileError}
                                                    // helperText={IsFileError ? "Select a file" : ''}
                                                    validators={["required"]}
                                                    errorMessages={["Select a file"]}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">{radioValue === 2 ? 'Choose file*' : 'Choose file'}</InputAdornment>,
                                                    }}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                                :
                                                <TextField
                                                    style={{ display: 'block', height: 25, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold' }}
                                                    onClick={() => {
                                                        console.log("upload ref ==> ", fileUploadRef.current)
                                                        document.getElementById('getFile').click()
                                                    }
                                                    }
                                                    placeholder="Upload Script File"
                                                    disabled
                                                    fullWidth={true}
                                                    errorMessages={[""]}
                                                    value={uploadSelectedFile.length > 0 ? uploadSelectedFile.length + ' Files selected' : null}
                                                    // validators={["required"]}
                                                    // error={IsFileError}
                                                    // helperText={IsFileError ? "Select a file" : ''}
                                                    // validators={radioValue === '2' ? ["required"] : false}
                                                    // errorMessages={radioValue === '2' ? ["Select a file"] : [""]}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">{radioValue === 2 ? 'Choose file*' : 'Choose file'}</InputAdornment>,
                                                    }}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />

                                            }

                                            <TextField
                                                ref={fileUploadRef}
                                                size='medium'
                                                id="getFile"
                                                name="avatar"
                                                value={uploadFile}
                                                type={"file"}
                                                inputProps={{ multiple: true, accept: "application/pdf,.docx,.doc,application/msword" }}
                                                onChange={fileChangedHandler}
                                                fullWidth
                                                style={{ display: 'none' }}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            />





                                            <TextField
                                                style={{ display: 'block', height: 25, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold', paddingTop: 35 }}
                                                onClick={() => {
                                                    console.log("upload ref ==> ", videoUploadRef.current)
                                                    document.getElementById('getFileVideo').click()
                                                }
                                                }
                                                placeholder="Upload Script Video File"
                                                disabled
                                                fullWidth={true}
                                                value={uploadSelectedVideoFile.length > 0 ? uploadSelectedVideoFile.length + ' Videos selected' : null}
                                                // validators={["required"]}
                                                // errorMessages={["Select a Video file"]}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">Choose file</InputAdornment>,
                                                }}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            />

                                            <TextField
                                                ref={videoUploadRef}
                                                size='medium'
                                                id="getFileVideo"
                                                name="avatar"
                                                value={uploadVideoFile}
                                                type={"file"}
                                                inputProps={{ multiple: true, accept: "video/*" }}
                                                onChange={fileChangedHandlerVideo}
                                                fullWidth
                                                style={{ display: 'none' }}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            />
                                            {/* {uploadSelectedFile.length > 0 && (
                                                        <div className="alert alert-secondary" role="alert">
                                                            <ul>
                                                                {uploadSelectedFile.length > 0 && uploadSelectedFile.map((item, i) => {
                                                                    <li key={i}>{item.name}</li>
                                                                })}
                                                            </ul>
                                                            {uploadSelectedFile[0].name}
                                                        </div>
                                                    )} */}
                                            {/* {uploadSelectedFile}

                                                    <div>
                                                        {uploadSelectedFile.length > 0 && <div className="card">
                                                            <div className="card-header">List of Files</div>
                                                            <ul className="list-group list-group-flush">
                                                                {uploadSelectedFile &&
                                                                    uploadSelectedFile.map((file, index) => (
                                                                        <li className="list-group-item" key={index}>
                                                                            {file.name}
                                                                        </li>
                                                                    ))}
                                                            </ul>
                                                        </div>
                                                        }
                                                    </div> */}
                                        </Grid>
                                        {/* </>
                                        } */}
                                        <Grid item xs={4} md={4} lg={4} style={{ marginTop: isNonMobile ? -20 : 20 }}>
                                            <TextField
                                                label="Description"
                                                value={description}
                                                inputProps={{ tabIndex: 16 }}
                                                size='medium'
                                                onChange={(event) => {
                                                    if (event.target.value.length <= 3000) {
                                                        setDescription(event.target.value)
                                                    }
                                                }}
                                                autoComplete="off"
                                                maxLength={5}
                                                // style={{ marginBottom: 16 }}
                                                multiline
                                                minRows={4.5}
                                                maxRows={8}
                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                            />
                                            <div style={{ marginTop: -10, display: 'flex', flexDirection: 'row-reverse' }}>
                                                <Typography style={{ color: '#9A9A9A' }}>{description === '' ? 0 : description.length}/3000</Typography>
                                            </div>
                                        </Grid>
                                        {/* <Grid item xs={4} md={4} style={{ marginTop: isNonMobile ? -20 : 20 }}>
                                            <Stack spacing={2} direction="row">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        label="Publish On*"
                                                        size='medium'
                                                        value={publishOn}
                                                        onChange={(newValue) => {
                                                            if (newValue) {
                                                                if (newValue.$d == "Invalid Date") {
                                                                    setvalidTill(null);
                                                                }
                                                                setPublishOn(newValue.$d);
                                                            }
                                                        }}
                                                        open={openPublishOn}
                                                        onOpen={() => setOpenPublishOn(true)}
                                                        onClose={() => setOpenPublishOn(false)}
                                                        disablePast
                                                        format="DD-MM-YYYY"
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                size='medium'
                                                                inputProps={{ ...params.inputProps, tabIndex: 13, readOnly: true }}
                                                                value={publishOn}
                                                                autoComplete="off"
                                                                onClick={() => handleTextFieldClick('publishOn')}
                                                                validators={["required"]}
                                                                errorMessages={["Publish On is required"]}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />}
                                                    // minDate={new Date()}
                                                    />
                                                    <DatePicker
                                                        label={"Valid Till*"}
                                                        value={validTill}
                                                        size='medium'
                                                        minDate={publishOn}
                                                        maxDate={endDate}
                                                        open={openValidTill}
                                                        onOpen={() => setOpenValidTill(true)}
                                                        onClose={() => setOpenValidTill(false)}
                                                        onChange={(newValue) => {
                                                            if (newValue) {
                                                                setvalidTill(newValue.$d);
                                                            }
                                                        }}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                inputProps={{ ...params.inputProps, tabIndex: 14, readOnly: true }}
                                                                size='medium'
                                                                value={validTill}
                                                                onClick={() => handleTextFieldClick('validTill')}
                                                                autoComplete="off"
                                                                validators={["required"]}
                                                                errorMessages={["Valid Till is required"]}
                                                                onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                            />}
                                                        format="DD-MM-YYYY"
                                                        disabled={publishOn == "Invalid Date" ? true : false}
                                                    />

                                                </LocalizationProvider>
                                            </Stack>
                                        </Grid> */}



                                    </Grid>
                                    <Grid style={{ paddingTop: 16 }}>
                                        <Button disabled={onApiLoading} variant="contained" type="submit" size='medium' tabIndex={15} sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }}>
                                            {/* <Icon>send</Icon> */}
                                            {onApiLoading === true ?
                                                <CircularProgress size={20} color='inherit' sx={{ alignItems: 'center' }} />
                                                :
                                                <>
                                                    {use == 'create' ? "Submit" : "Update"}
                                                </>
                                            }
                                        </Button>
                                    </Grid>

                                </ValidatorForm>
                            </SimpleCard>

                        </>
                        :
                        <>
                            {
                                APILoading ?
                                    <Loading />
                                    :

                                    <ValidatorForm onSubmit={() => onSubmitPost(use == 'create' ? "Insert" : "Update")} onError={() => null} >
                                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    options={projectList}
                                                    size='medium'
                                                    getOptionLabel={(option) => option.projectName}
                                                    disabled={true}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Select Project*"
                                                            value={projectSelected}
                                                            fullWidth
                                                            validators={["required"]}
                                                            errorMessages={["Project is required"]}
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        />
                                                    )}
                                                    value={projectSelected}
                                                    onChange={(_, newValue) => {
                                                        setProjectSelected(newValue);
                                                        setCharacterData(newValue.characterData)
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: isNonMobile == false && -20 }}>
                                                <Autocomplete
                                                    options={characterData}
                                                    size='medium'
                                                    // getOptionLabel={(option) => option.label}
                                                    disabled={true}
                                                    getOptionLabel={(option) => option.characterName}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Select Character*"
                                                            value={charSelected}
                                                            fullWidth
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        // validators={["required"]}
                                                        // errorMessages={["Character is required"]}
                                                        />
                                                    )}
                                                    value={charSelected}
                                                    onChange={(_, newValue) => {
                                                        setCharSelected(newValue);
                                                    }}
                                                />
                                                {/* <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={categoryList}
                                                getOptionLabel={(option) => option.workExpCategoryName}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Select Category"
                                                        fullWidth
                                                    />
                                                )}
                                                // style={{ marginBottom: 16 }}
                                                value={userCategoryValue}
                                                onChange={(_, newValue) => {
                                                    setUserCategoryValue(newValue);
                                                }}
                                            /> */}

                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: isNonMobile == false && -30, marginBottom: isNonMobile == false && -10 }}>
                                                <FormControl style={{ marginBottom: 21, marginTop: 6 }}>
                                                    {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        // defaultValue="onSite"
                                                        name="radio-buttons-group"
                                                        row={isNonMobile ? true : false}
                                                        size='small'
                                                        value={radioValue}
                                                        onChange={handleChange}
                                                    >
                                                        {/* <FormControlLabel label="On Site" size='medium' /> */}
                                                        <Typography style={{ textAlign: 'center', marginTop: 10, marginRight: 5, fontSize: 13 }}>Audition Type :</Typography>
                                                        <FormControlLabel value={1} control={<Radio tabIndex={1} />} label="On Site" size='small' sx={{ fontSize: 10, marginRight: 1 }} />
                                                        <FormControlLabel value={2} control={<Radio tabIndex={2} />} label="Online" size='small' sx={{ fontSize: 10 }} />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4} style={{ marginTop: -20 }}>
                                                <TextField
                                                    label="Title*"
                                                    value={title}
                                                    onChange={(event) =>
                                                        setTitle(event.target.value)
                                                    }
                                                    inputProps={{ tabIndex: 3 }}
                                                    // style={{ marginBottom: 16 }}
                                                    fullWidth
                                                    validators={["required"]}
                                                    errorMessages={["Title is required"]}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: -20 }}>
                                                <Stack spacing={2} direction="row" >
                                                    <TextField
                                                        label="From Age*"
                                                        value={fromAge}
                                                        inputProps={{ tabIndex: 4 }}
                                                        onChange={(event) => {
                                                            const test = NumberValidate(event.target.value)
                                                            if (event.target.value.length <= 3) {
                                                                if (test || event.target.value == "") {
                                                                    setAgeFrom(event.target.value)
                                                                }
                                                            }
                                                        }}
                                                        multiline
                                                        validators={["required"]}
                                                        autoComplete="off"
                                                        errorMessages={["From Age is required"]}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                    <p style={{ paddingTop: 15 }}>To</p>
                                                    <TextField
                                                        label="To Age*"
                                                        value={toAge}
                                                        inputProps={{ tabIndex: 5 }}
                                                        autoComplete="off"
                                                        onChange={(event) => {
                                                            const test = NumberValidate(event.target.value)
                                                            if (event.target.value.length <= 3) {
                                                                if (test || event.target.value == "") {
                                                                    setToAge(event.target.value)
                                                                }
                                                            }
                                                        }}
                                                        validators={["required"]}
                                                        errorMessages={["To Age is required"]}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: -20 }}>
                                                <Autocomplete
                                                    // multiple
                                                    // id="tags-outlined"
                                                    options={userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1 ? yrfGender : gender}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => option.value == value.value}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, tabIndex: 6 }}
                                                            variant="outlined"
                                                            label="Select Gender*"
                                                            value={userGenderValue}
                                                            fullWidth
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        // validators={["required"]}
                                                        // errorMessages={["Gender is required"]}
                                                        />
                                                    )}
                                                    // defaultValue={gender[0]}
                                                    value={userGenderValue}
                                                    // style={{ marginBottom: 16 }}
                                                    onChange={(_, newValue) => {
                                                        setUserGenderValue(newValue);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4} style={{ marginTop: -20 }}>
                                                <Autocomplete
                                                    multiple
                                                    size='medium'
                                                    id="tags-outlined-countries"
                                                    options={allCountries}
                                                    getOptionLabel={(option) => option.label}
                                                    isOptionEqualToValue={(option, value) => option.value == value.value}
                                                    // renderOption={(props, option) => {
                                                    //     return (
                                                    //         <li {...props} key={option.value}>
                                                    //             {option.label}
                                                    //         </li>
                                                    //     );
                                                    // }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, tabIndex: 9 }}
                                                            variant="outlined"
                                                            label="Select Country"
                                                            fullWidth
                                                            value={userCountryValue}
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        />
                                                    )}
                                                    value={userCountryValue}
                                                    // style={{ marginBottom: 16 }}
                                                    onChange={(_, newValue) => {
                                                        if (newValue.length == 0) {
                                                            setUserCountryValue(newValue);
                                                            setStates([])
                                                            setCities([])
                                                            setUserStateValue([])
                                                            setUserCityValue([])
                                                        } else {
                                                            onChangeCountry(newValue)
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: -20 }}>
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={States}
                                                    isOptionEqualToValue={(option, value) => option.value == value.value}
                                                    autoSelect={true}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.value}>
                                                                {option.label}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, tabIndex: 7 }}
                                                            variant="outlined"
                                                            label="Select State"
                                                            fullWidth
                                                            value={userStateValue}
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        />
                                                    )}
                                                    value={userStateValue}
                                                    // style={{ marginBottom: 16 }}
                                                    onChange={(_, newValue) => {
                                                        setUserStateValue(newValue);
                                                        if (newValue.length == 0) {
                                                            // setCities(allCities)
                                                            setCities([])
                                                            setUserCityValue([])
                                                        } else {
                                                            let filterStateVal = newValue.filter((el) => {
                                                                // let tempCity = UserCityValue.filter((element) => {
                                                                //     if (element.stateId == el.value) {
                                                                //         return element;
                                                                //     }
                                                                // })
                                                                // if (tempCity.length !== 0) {
                                                                //     setUserCityValue(tempCity)
                                                                // }
                                                            })
                                                            let cityFilter = allStatesCity.filter((el) => el.stateId == newValue[newValue.length - 1].value);
                                                            let temp = cityFilter.map(el => ({ label: el.city, value: el.cityId, stateId: el.stateId }))
                                                            if (newValue.length == 1) {
                                                                setCities(temp);
                                                            } else {
                                                                setCities([...cities, ...temp]);
                                                            }
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: -20 }}>
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={userStateValue.length == 0 ? allCities : cities}
                                                    isOptionEqualToValue={(option, value) => option.value == value.value}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={option.value}>{option.label}</li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, tabIndex: 8 }}
                                                            variant="outlined"
                                                            label="Select City"
                                                            value={UserCityValue}
                                                            fullWidth
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        />
                                                    )}
                                                    value={UserCityValue}
                                                    // style={{ marginTop: 16, marginBottom: 16 }}
                                                    onChange={(_, newValue) => {
                                                        setUserCityValue(newValue);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: -20 }}>
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={languagesList}
                                                    getOptionLabel={(option) => option.languageName}
                                                    isOptionEqualToValue={(option, value) => option.languageId == value.languageId}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{ ...params.inputProps, tabIndex: 9 }}
                                                            variant="outlined"
                                                            label="Select Language"
                                                            fullWidth
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        />
                                                    )}
                                                    // style={{ marginBottom: 16 }}
                                                    value={UserLanguageValue}
                                                    onChange={(_, newValue) => {
                                                        setUserLanguageValue(newValue);
                                                    }}
                                                />
                                                <Stack spacing={2} direction="row">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            label="Publish On*"
                                                            value={dayjs(publishOn)}
                                                            minDate={dayjs(publishOn)}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    if (newValue.$d == "Invalid Date") {
                                                                        setvalidTill(null);
                                                                    }
                                                                    if (moment(newValue.$d).format("YYYY-MM-DD") > validTill) {
                                                                        setvalidTill(null);
                                                                    }
                                                                    setPublishOn(newValue);
                                                                }
                                                            }}
                                                            open={openPublishOn}
                                                            onOpen={() => setOpenPublishOn(true)}
                                                            onClose={() => setOpenPublishOn(false)}
                                                            format="DD-MM-YYYY"
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    value={dayjs(publishOn)}
                                                                    inputProps={{ ...params.inputProps, tabIndex: 11 }}
                                                                    validators={["required"]}
                                                                    autoComplete="off"
                                                                    onClick={() => handleTextFieldClick('publishOn')}
                                                                    errorMessages={["Publish On is required"]}
                                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                />}
                                                        />
                                                        <DatePicker
                                                            label={"Valid Till*"}
                                                            value={dayjs(validTill)}
                                                            onChange={(newValue) => {
                                                                if (newValue) {
                                                                    setvalidTill(newValue.$d);
                                                                }
                                                            }}
                                                            // maxDate={endDate}
                                                            open={openValidTill}
                                                            onOpen={() => setOpenValidTill(true)}
                                                            onClose={() => setOpenValidTill(false)}
                                                            renderInput={(params) =>
                                                                <TextField {...params}
                                                                    inputProps={{ ...params.inputProps, tabIndex: 12, readOnly: true }}
                                                                    value={dayjs(validTill)}
                                                                    onClick={() => handleTextFieldClick('validTill')}
                                                                    validators={["required"]}
                                                                    autoComplete="off"
                                                                    errorMessages={["Valid Till is required"]}
                                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                                />}
                                                            minDate={dayjs(publishOn)}
                                                            format="DD-MM-YYYY"
                                                            disabled={publishOn == "Invalid Date" ? true : false}
                                                        />

                                                    </LocalizationProvider>
                                                </Stack>
                                            </Grid>
                                            {/* {(radioValue == 2 || userFromSession.organizationId === 6) && */}

                                            <Grid item xs={4} sm={2} md={4} style={{ marginTop: -20, marginBottom: 20 }}>
                                                {/* <TextField
                                                            style={{ display: 'block', height: 30, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold' }}
                                                            onClick={() => {
                                                                console.log("upload ref ==> ", fileUploadRef.current)
                                                                document.getElementById('getFile').click()
                                                            }
                                                            }
                                                            placeholder="Upload Audition Script File*"
                                                            disabled
                                                            fullWidth={true}
                                                            value={uploadSelectedFile.name}
                                                            validators={["required"]}
                                                            errorMessages={["Select a file"]}
                                                            InputProps={{
                                                                startAdornment: <InputAdornment position="start">Choose file*</InputAdornment>,
                                                            }}
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        />

                                                        <TextField
                                                            // label="Upload*"
                                                            ref={fileUploadRef}
                                                            size='medium'
                                                            id="getFile"
                                                            name="avatar"
                                                            value={uploadFile}
                                                            inputProps={{ accept: "application/pdf,.docx,.doc,application/msword" }}
                                                            onChange={(event) => {
                                                                if (event.target.value.length !== 0) {
                                                                    setUploadFile(event.target.value)
                                                                    setUploadSelectedFile(event.target.files[0])
                                                                    setFileFlag(true);
                                                                } else {
                                                                    setUploadFile(event.target.value)
                                                                }
                                                            }}
                                                            fullWidth
                                                            type='file'
                                                            style={{ display: 'none' }}
                                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                        /> */}

                                                {radioValue === '2' ?
                                                    <TextField
                                                        style={{ display: 'block', height: 25, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold' }}
                                                        onClick={() => {
                                                            console.log("upload ref ==> ", fileUploadRef.current)
                                                            document.getElementById('getFile').click()
                                                        }
                                                        }
                                                        placeholder="Upload Script File*"
                                                        disabled
                                                        fullWidth={true}
                                                        value={uploadSelectedFile.length > 0 ? uploadSelectedFile.length + ' Files selected' : null}
                                                        // validators={["required"]}
                                                        // error={IsFileError}
                                                        // helperText={IsFileError ? "Select a file" : ''}
                                                        validators={["required"]}
                                                        errorMessages={["Select a file"]}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{radioValue === 2 ? 'Choose file*' : 'Choose file'}</InputAdornment>,
                                                        }}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                    :
                                                    <TextField
                                                        style={{ display: 'block', height: 25, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold' }}
                                                        onClick={() => {
                                                            console.log("upload ref ==> ", fileUploadRef.current)
                                                            document.getElementById('getFile').click()
                                                        }
                                                        }
                                                        placeholder="Upload Script File"
                                                        disabled
                                                        fullWidth={true}
                                                        value={uploadSelectedFile.length > 0 ? uploadSelectedFile.length + ' Files selected' : null}
                                                        // validators={["required"]}
                                                        // error={IsFileError}
                                                        // helperText={IsFileError ? "Select a file" : ''}
                                                        // validators={radioValue === '2' ? ["required"] : false}
                                                        // errorMessages={radioValue === '2' ? ["Select a file"] : [""]}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">{radioValue === 2 ? 'Choose file*' : 'Choose file'}</InputAdornment>,
                                                        }}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />

                                                }

                                                <TextField
                                                    ref={fileUploadRef}
                                                    size='medium'
                                                    id="getFile"
                                                    name="avatar"
                                                    value={uploadFile}
                                                    type={"file"}
                                                    inputProps={{ multiple: true, accept: "application/pdf,.docx,.doc,application/msword" }}
                                                    onChange={fileChangedHandler}
                                                    fullWidth
                                                    style={{ display: 'none' }}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />





                                                <TextField
                                                    style={{ display: 'block', height: 25, borderColor: 'grey', borderWidth: 1, fontWeight: 'bold', paddingTop: 35 }}
                                                    onClick={() => {
                                                        console.log("upload ref ==> ", videoUploadRef.current)
                                                        document.getElementById('getFileVideo').click()
                                                    }
                                                    }
                                                    placeholder="Upload Script Video File"
                                                    disabled
                                                    fullWidth={true}
                                                    value={uploadSelectedVideoFile.length > 0 ? uploadSelectedVideoFile.length + ' Videos selected' : null}
                                                    // validators={["required"]}
                                                    // errorMessages={["Select a Video file"]}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">Choose file</InputAdornment>,
                                                    }}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />

                                                <TextField
                                                    ref={videoUploadRef}
                                                    size='medium'
                                                    id="getFileVideo"
                                                    name="avatar"
                                                    value={uploadVideoFile}
                                                    type={"file"}
                                                    inputProps={{ multiple: true, accept: "video/*" }}
                                                    onChange={fileChangedHandlerVideo}
                                                    fullWidth
                                                    style={{ display: 'none' }}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} style={{ marginTop: isNonMobile ? -20 : 20 }}>
                                                <TextField
                                                    label="Description"
                                                    value={description}
                                                    onChange={(event) => {
                                                        if (event.target.value.length <= 3000) {
                                                            setDescription(event.target.value)
                                                        }
                                                    }}
                                                    inputProps={{ tabIndex: 10 }}
                                                    // style={{ marginBottom: 16 }}
                                                    autoComplete="off"
                                                    multiline
                                                    minRows={4.5}
                                                    maxRows={8}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                                <div style={{ marginTop: -10, display: 'flex', flexDirection: 'row-reverse' }}>
                                                    <Typography style={{ color: '#9A9A9A' }}>{description === '' ? 0 : description.length}/3000</Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div style={{ paddingTop: 16, flexDirection: 'row-reverse', display: 'flex' }}>
                                            <Button disabled={onApiLoading} variant="contained" type="submit" size='large' tabIndex={13} sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }}>
                                                {/* <Span sx={{ textTransform: "capitalize" }}>{use == 'create' ? "Submit" : "Update"}</Span> */}
                                                {onApiLoading === true ?
                                                    <CircularProgress size={20} color='inherit' sx={{ alignItems: 'center' }} />
                                                    :
                                                    <>
                                                        {use == 'create' ? "Submit" : "Update"}
                                                    </>
                                                }
                                            </Button>
                                        </div>
                                    </ValidatorForm>
                            }

                        </>
                    }
                </Stack>
                {
                    popUp &&
                    <Box>
                        <Dialog
                            open={popUp}
                            // fullWidth={true}
                            // maxWidth={'md'}
                            onClose={() => { !APILoading && setPopUp(false); setCharSelected(null) }}
                            aria-labelledby="form-dialog-title"
                        >
                            {
                                APILoading ?
                                    <Loading />
                                    :
                                    <>
                                        <DialogTitle style={{ textAlign: "center" }}>
                                            {popUpSubmit == true && 'Success!'}

                                            {popUpSubmit == true && <IconButton
                                                aria-label="close"
                                                onClick={() => setPopUp(false)}
                                                sx={{
                                                    position: 'absolute',
                                                    right: 8,
                                                    top: 10,
                                                    color: "grey",
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>}
                                        </DialogTitle>

                                        <DialogContent>
                                            <p style={{ textAlign: "center", marginTop: popUpSubmit == true ? 0 : 20, padding: popUpSubmit == true ? 0 : 20 }}>{popUpSubmit == false && popUpMessage} <br />{popUpSubmit == false ? popUpDesc : 'Audition created successfully'}</p>
                                            {popUpSubmit == true ?
                                                <Stack spacing={3} direction='row' style={{ justifyContent: "center" }} >
                                                    <Button color="primary" variant="contained" type="submit" size='large' onClick={() => navigate('/dashboard')}>
                                                        <Span sx={{ textTransform: "capitalize" }}>Home</Span>
                                                    </Button>
                                                    <Button color="primary" variant="contained" type="submit" size='large' onClick={() => navigate('/post/ListPost')}>
                                                        <Span sx={{ textTransform: "capitalize" }}>List</Span>
                                                    </Button>
                                                </Stack>
                                                :
                                                <Stack spacing={3} direction='row' style={{ justifyContent: "center" }} >

                                                    <Button color="primary" variant="contained" type="submit" size='large' onClick={() => { setPopUp(false); setCharSelected(null); }}>
                                                        <Span sx={{ textTransform: "capitalize" }}>Ok</Span>
                                                    </Button>

                                                </Stack>
                                            }
                                        </DialogContent>
                                    </>

                            }

                        </Dialog>
                    </Box>
                }

                {snackOpen &&
                    <CustomSnackBar
                        openSnackBar={snackOpen}
                        handleCloseSnackBar={handleCloseSnackBar}
                        // severity="success"
                        severity={snackBarMessage == "Server Error" || "Please upload a file smaller than 10 MB" ? "error" : "Enter Valid Age" ? "warning" : "Audition updated successfully" ? "Success" : "success"}
                        message={snackBarMessage}
                    />
                }
                {
                    errorPopUp &&
                    <ServerErrorModal
                        popUpFor={errorMsg}
                        openAlert={errorPopUp}
                        handleCloseAlert={handleServerError}
                    />
                }
            </div>
        </DocumentTitle>

    )
}

export default CreateUpdatePost;
