
import { endPointName } from 'apis/apiList';
import { CallAPI } from 'apis/callAPI';
import React, { useEffect, useState } from 'react';
import { Cloud_Logo, Fb_Logo, YT_Logo, Insta_Logo, IMDb_Logo, Audition_Icon, Default_Logo, CoverDefault_Logo, x_Twitter_Logo } from '../../../images';
import { Breadcrumb, CustomSnackBar, PostTableLayout, SimpleCard } from 'app/components';
import {
    Box,
    Card,
    Divider,
    Grid,
    Icon,
    IconButton,
    Stack,
    Button,
    Typography,
    styled,
    DialogTitle,
    DialogContentText,
    Dialog,
    DialogActions,
    DialogContent,
    useMediaQuery,
    Autocomplete,
    TextField
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import { BiError } from 'react-icons/bi'
import { AiOutlineLink } from 'react-icons/ai'
import { VscVerifiedFilled } from 'react-icons/vsc'
import TabPanel from '@mui/lab/TabPanel';
import moment from 'moment';
import ModalView from 'app/components/ModalView';
import { cmToFeetInches } from 'utils/utilities';
import { TabList } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import { areaTypes, stageStatus, ageCategory } from 'app/constant';
import { catchError } from 'app/firebase/Crashlytics';
import { PopUpModal } from 'app/components/PopUpModal';
import DocumentTitle from 'react-document-title';
import ValidationPopUp from 'app/components/ValidationPopUp';
import useAuth from 'app/hooks/useAuth';
import ServerErrorModal from 'app/components/ServerErrorModal';
import { Span } from 'app/components/Typography';
import { FcApproval } from 'react-icons/fc';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const CardTitle = styled('div')(({ subtitle }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: '10px',
    // backgroundColor: '#ea384d',
}));

const CardRoot = styled(Card)(() => ({
    height: '100%',
    // padding: '20px 24px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

const UserDetails = () => {
    const { logout, user } = useAuth();
    const userFromSession = JSON.parse(sessionStorage.getItem("userJSON"));
    const accessToken = window.localStorage.getItem('accessToken')
    const [allDetails, setAllDetails] = useState(null);
    const [postDetails, setPostDetails] = useState([]);
    const [educationData, setEducationData] = useState([]);
    const [show, setShow] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [deactivateModal, setDeactivateModal] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [img, setImg] = useState();
    const [singlePostData, setSinglePostData] = useState()
    const [openUpadteModal, setOpenUpadteModal] = useState(false);
    const [openStageDialog, setOpenStageDialog] = useState(false);
    const [selectedRowData, setselectedRowData] = useState();
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSnackBarMsg, setOpenSnackBarMsg] = useState('');
    const [value, setValue] = React.useState('1');
    const [galleryImg, setGalleryImg] = useState([]);
    const [ageValue, setAgeValue] = useState(null);
    const [areaValue, setAreaValue] = useState(null);
    const [tagsOpen, setTagsOpen] = useState(true);
    const [editTag, setEditTag] = useState(false);
    const [tagFields, setTagFields] = useState(false);
    const [displayFields, setDisplayFields] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [popUp, setPopUp] = useState(false);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [isDeactivated, setIsDeactivated] = useState(false);
    const navigate = useNavigate();
    const [isOnline, setOnline] = useState(true);
    const controller = new AbortController();
    const signal = controller.signal;
    const isNonMobile = useMediaQuery("(min-width:1030px)");
    const { id } = useParams()
    const conditionalRowStyles = [
        {
            when: row => row.applicationStatus == 1,
            style: {
                backgroundColor: '#5ba78b',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.applicationStatus == 2,
            style: {
                backgroundColor: '#eab448',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.applicationStatus == 3,
            style: {
                backgroundColor: '#e0746e',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    const columns = [
        {
            id: 'postid',
            name: 'Id',
            width: isNonMobile ? '7%' : '8%',
            selector: row => row.postId,
            sortable: true

        },
        {
            id: 'projectName',
            name: 'Project Name',
            selector: row => row.projectName,
            sortable: true,
            wrap: true,
            width: isNonMobile ? '12%' : '20%'
        },
        {
            id: 'title',
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            wrap: true,
            width: '16%'
        },
        {
            id: 'charcter',
            name: 'Character',
            selector: row => row.characterName,
            sortable: true,
            wrap: true,
            width: isNonMobile ? '10%' : '14%'
        },
        {
            id: 'appliedOn',
            name: 'Applied On',
            selector: row => moment(row.appliedDate).format('DD MMM YYYY'),
            sortable: true,
            width: isNonMobile ? '8%' : '14%'
        },
        {
            id: 'stage',
            name: 'Stage',
            selector: row => {
                return (
                    <StyledButton variant="outlined" color="primary" style={{ height: '25px', marginLeft: 0, color: row.applicationStatus === 1 ? 'white' : row.applicationStatus === 2 ? 'white' : row.applicationStatus === 3 ? 'white' : 'black', borderColor: row.applicationStatus === 1 ? 'white' : row.applicationStatus === 2 ? 'white' : row.applicationStatus === 3 ? 'white' : 'lightgray' }} onClick={() => handleClickOpenDialog(row)}>
                        {row.stage}
                    </StyledButton>
                )
            },
            sortable: true,
            width: isNonMobile ? '9%' : '13%'
        },
        {
            id: 'status',
            name: 'Status',
            selector: row => {
                return (
                    <StyledButton variant="outlined" color="primary" disabled={true} style={{ marginLeft: 0, height: '25px', fontSize: 12, width: '120px', color: row.applicationStatus === 1 ? 'white' : row.applicationStatus === 2 ? 'white' : row.applicationStatus === 3 ? 'white' : 'black', borderColor: row.applicationStatus === 1 ? 'white' : row.applicationStatus === 2 ? 'white' : row.applicationStatus === 3 ? 'white' : 'lightgray' }}>
                        {row.applicationStatus === 4 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}
                    </StyledButton>
                )
            },
            wrap: true,
            width: isNonMobile ? '15%' : '17%'
        },
        {
            id: 'remarks',
            name: 'Remark',
            selector: row => row.remark,
            wrap: true,
            width: '18%'
        },
        {
            id: 'action',
            name: 'Action',
            selector: row => {
                return (
                    <>
                        <IconButton color="primary" className="button" aria-label="View" onClick={() => onClickView(row)}>
                            <Icon style={{ color: row.applicationStatus === 4 ? 'primary' : 'white' }}>remove_red_eye</Icon>
                        </IconButton>
                    </>
                )
            },
            button: true,
            width: isNonMobile && '5%'
        }
    ];

    const handleClickOpenDialog = async (data) => {
        try {
            setOpenStageDialog(true);
            setselectedRowData(data)
        } catch (error) {
            catchError(error, 'UserDetails_1');
        }
    }

    const handleCloseSnackBar = (_, reason) => {
        try {

            if (reason === "clickaway") {
                return;
            }
            setOpenSnackBar(false);
        } catch (error) {
            catchError(error, 'UserDetails_2');
        }
    }

    const onClickView = (row) => {
        try {
            setOpenUpadteModal(false);
            // setOpenViewModal(true);
            setSinglePostData(row);
            window.sessionStorage.setItem('dataUserId', JSON.stringify(id))
            window.sessionStorage.setItem('postData', JSON.stringify({ postData: row, location: 'UserDetails' }))
            navigate('/post/DetailPost');
        } catch (error) {
            catchError(error, 'UserDetails_3');
        }
    }

    const handleChange = (event, newValue) => {
        try {
            setValue(newValue);
        } catch (error) {
            catchError(error, 'UserDetails_4');
        }
    };

    const handleClose = () => {
        try {
            setShow(false)
            setImg('');
        } catch (error) {
            catchError(error, 'UserDetails_5');
        }
    };
    const handleShow = (imgData) => {
        try {
            setShow(true)
            setImg(imgData);
        } catch (error) {
            catchError(error, 'UserDetails_6');
        }
    };

    function handleCloseDialog() {
        try {
            setOpenStageDialog(false);
        } catch (error) {
            catchError(error, 'UserDetails_7');
        }
    }
    const onSubmitStageData = async () => {
        try {
            let sessionData = JSON.parse(window.sessionStorage.getItem("dataUserId"))
            setOpenStageDialog(false);
            setOpenSnackBarMsg('Stage updated successfully')
            setOpenSnackBar(true)
            const payload = {
                // UserId: sessionData.userId
                UserId: id
            }
            let postResponse = await CallAPI(endPointName.adminGetPostsAppliedByUserId, "POST", payload);
            if (postResponse !== 'error') {
                setPostDetails(postResponse);
            } else {
                setErrorMsg('Server Error')
                setErrorPopUp(true)
                setPostDetails([])
            }
        } catch (error) {
            catchError(error, 'UserDetails_8');
        }
    }

    const getUserDetails = async () => {
        try {

            let sessionData = JSON.parse(window.sessionStorage.getItem("dataUserId"))
            const payload = {
                // UserId: sessionData !== undefined || sessionData !== null ? sessionData.userId : ''
                UserId: id
            }
            // let response = await CallAPI(endPointName.SingleUserDetails + sessionData.userId, "post");
            let response = await CallAPI(endPointName.SingleUserDetails + id, "post");
            if (response !== 'error') {
                let educationResponse = await CallAPI(endPointName.GetEducationTrainingDetails, "POST", payload);
                if (educationResponse.statusCode === 200) {
                    const result = [];
                    educationResponse.tblEducationTraining.forEach(entry => {
                        const existingType = result.find(item => item.educationType === entry.educationType);
                        if (existingType) {
                            existingType.trainingInstName.push(entry.trainingInstName);
                        } else {
                            if (entry.trainingInstName !== '' && entry.trainingInstName !== null) {
                                result.push({
                                    educationType: entry.educationType,
                                    trainingInstName: [entry.trainingInstName]
                                });
                            }
                        }
                    });
                    setEducationData(result);

                } else {
                    setErrorMsg('Server Error')
                    setErrorPopUp(true)
                }
                let postResponse = await CallAPI(endPointName.adminGetPostsAppliedByUserId, "POST", payload);
                setPostDetails(postResponse);
                setAllDetails(response);
                if (response.listUserImageGallery !== null) {
                    let tempImages = [
                        { galleryId: 0, imageURL: '' },
                        { galleryId: 0, imageURL: '' },
                        { galleryId: 0, imageURL: '' },
                        { galleryId: 0, imageURL: '' }
                    ];
                    for (let i = 0; i < response.listUserImageGallery.length; i++) {
                        tempImages[i].imageURL = response.listUserImageGallery[i].imageURL;
                    }
                    setGalleryImg(tempImages);
                }
                setIsVerified(response.singleUserData.isVerified === 0 ? false : true);
                getTagsApiData();
            } else {
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, 'UserDetails_9');
        }
    }

    const handleCloseAlert = () => {
        try {
            setErrorPopUp(false)
            setErrorMsg('');
            window.close();
        } catch (error) {
            catchError(error, 'UserDetails_10');
        }
    }

    useEffect(() => {
        try {
            if (accessToken !== null) {
                if (isOnline) {
                    getUserDetails()
                } else {
                    setErrorMsg('No Internet')
                    setErrorPopUp(true)
                }
            } else {
                logout()
            }
        } catch (error) {
            catchError(error, 'UserDetails_11');
        }
    }, [allDetails !== undefined, accessToken])

    const onClickTags = () => {
        try {
            setTagsOpen(false);
            setTagFields(true);
        } catch (error) {
            catchError(error, 'UserDetails_12');
        }
    }

    const getTagsApiData = async () => {
        try {
            let sessionData = JSON.parse(window.sessionStorage.getItem("dataUserId"))
            const payload = {
                // UserId: sessionData !== undefined || sessionData !== null ? sessionData.userId : '' || queryId
                UserId: id
            }
            let responseTags = await CallAPI(endPointName.GetTagsMaster, "post", payload);
            if (responseTags.length > 0) {
                setTagsData(responseTags);
                if (responseTags[0].areaType > 0) {
                    let areaTags = areaTypes.filter((el) => el.value == responseTags[0].areaType);
                    setAreaValue(areaTags[0]);
                }
                if (responseTags[0].ageCategory > 0) {
                    let ageTags = ageCategory.filter((el) => el.value == responseTags[0].ageCategory);
                    setAgeValue(ageTags[0]);
                }
                setDisplayFields(true);
                setTagsOpen(false);
            }
        } catch (error) {
            catchError(error, 'UserDetails_13');
        }
    }

    const onClickCancelTags = () => {
        try {
            if (editTag) {
                setTagsOpen(false);
                setTagFields(false);
                setDisplayFields(true);
            } else {
                setTagFields(false);
                setTagsOpen(true);
            }
        } catch (error) {
            catchError(error, 'UserDetails_14');
        }
    }

    const submitTags = async () => {
        try {
            if (isOnline == true) {
                if (areaValue == null && ageValue == null) {
                    setPopUp(true);
                } else {
                    let sessionData = JSON.parse(window.sessionStorage.getItem("dataUserId"))
                    if (editTag) {
                        let updatePayload = {
                            TagId: tagsData[0].tagId,
                            AreaType: areaValue !== null ? areaValue.value : 0,
                            AgeCategory: ageValue !== null ? ageValue.value : 0,
                            // UserId: sessionData !== undefined || sessionData !== null ? sessionData.userId : ''
                            UserId: id
                        }
                        let response = await CallAPI(endPointName.TagsUpdate, "post", updatePayload);
                        if (response.statusCode == 200) {
                            getTagsApiData()
                            setTagFields(false);
                            setDisplayFields(true);
                        } else if (response.statusCode == 400 || response.statusCode == 500) {
                            setErrorMsg('Server Error')
                            setErrorPopUp(true);
                        }
                    } else {
                        let insertPayload = {
                            AreaType: areaValue !== null ? areaValue.value : 0,
                            AgeCategory: ageValue !== null ? ageValue.value : 0,
                            // UserId: sessionData !== undefined || sessionData !== null ? sessionData.userId : ''
                            UserId: id
                        }
                        let response = await CallAPI(endPointName.TagsInsertion, "post", insertPayload);
                        if (response.statusCode == 200) {
                            getTagsApiData()
                            setTagFields(false);
                            setDisplayFields(true);
                        } else if (response.statusCode == 400 || response.statusCode == 500) {
                            setErrorMsg('Server Error')
                            setErrorPopUp(true);
                        }
                    }
                }
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, 'UserDetails_15');
        }
    }

    const onClickEdit = () => {
        try {
            setDisplayFields(false);
            setTagFields(true);
            setEditTag(true);
        } catch (error) {
            catchError(error, 'UserDetails_16');
        }
    }

    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
        controller.abort()
    });

    const scrollUp = () => {
        try {
            const element = document.getElementById("box");
            element.scrollIntoView({ behavior: "smooth", block: "start" });
        } catch (error) {
            catchError(error, "UserDetails_17");
        }
    }

    const onClickYesVerification = async () => {
        try {
            if (isOnline == true) {
                const payload = {
                    UserId: id,
                    IsVerified: verificationCode,
                    OrganizationId: userFromSession.organizationId
                }
                let responseVerification = await CallAPI(endPointName.UpdateUserVerification, "post", payload);
                if (responseVerification.statusCode == 200) {
                    if (verificationCode === 1) {
                        setIsVerified(true);
                    } else {
                        setIsVerified(false);
                    }
                    setConfirmModal(false);
                    scrollUp();
                } else if (responseVerification.statusCode == 400 || responseVerification.statusCode == 500) {
                    setErrorMsg('Server Error');
                    setConfirmModal(false);
                    setErrorPopUp(true);
                }
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, "UserDetails_18");
        }
    }
    const onClickVerification = () => {
        try {
            setVerificationCode(1);
            setConfirmModal(true);
        } catch (error) {
            catchError(error, "UserDetails_19");
        }
    }
    const onClickRemoveVerification = () => {
        try {
            setVerificationCode(0);
            setConfirmModal(true);
        } catch (error) {
            catchError(error, "UserDetails_20");
        }
    }
    const onClickNoVerification = () => {
        try {
            setVerificationCode('');
            setConfirmModal(false);
        } catch (error) {
            catchError(error, "UserDetails_21");
        }
    }

    const onClickDeactivateAccount = () => {
        try {
            setDeactivateModal(true);
        } catch (error) {
            catchError(error, "UserDetails_22");
        }
    }

    const onClickYesDeactivate = async () => {
        try {
            if (isOnline == true) {
                const payload = {
                    UserId: id
                }
                let response = await CallAPI(endPointName.DeactivateActorData, "post", payload);
                if (response.statusCode == 200) {
                    setIsDeactivated(true);
                    setOpenSnackBarMsg('Account deactivated successfully');
                    setOpenSnackBar(true);
                    setDeactivateModal(false);
                    window.close();
                } else {
                    setErrorMsg('Server Error');
                    setDeactivateModal(false);
                    setErrorPopUp(true);
                }
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, "UserDetails_22");
        }
    }

    const onClickNoDeactivate = () => {
        try {
            setDeactivateModal(false);
        } catch (error) {
            catchError(error, "UserDetails_23");
        }
    }


    return (
        <DocumentTitle title={'Actor Details'}>

            <Container>
                {
                    allDetails === null ?
                        <>
                            <div style={{ textAlign: 'center', justifyContent: 'center' }}>
                                <img src="/assets/images/reel.gif" alt="" style={{ height: 80, width: 80, marginTop: 20, marginBottom: 40 }} />
                            </div>

                            {errorPopUp == true &&
                                <ServerErrorModal
                                    popUpFor={errorMsg}
                                    openAlert={errorPopUp}
                                    handleCloseAlert={handleCloseAlert}
                                />
                            }
                        </>
                        :
                        <>
                            <Box className="breadcrumb" style={{ paddingLeft: 15, marginBottom: 10 }} id="box">
                                <Breadcrumb routeSegments={[{ name: 'Actor List', path: `/users/UserList`, namePage: 'Actors' }, { name: 'Actor Information', path: '/post/ListPost', namePage: 'Actors' }]} />
                            </Box>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: 2 }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="Profile" value="1" />
                                            <Tab label={`Audition Applied (${postDetails == 'error' ? '0' : (postDetails !== null && postDetails !== undefined) && postDetails.length})`} value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <Stack spacing={2}>
                                            <Grid container spacing={2} >
                                                <Grid style={{ width: '100%', }}>
                                                    <Grid container direction={"row"} columns={{ xs: 3, sm: 10, md: 12 }} style={{ marginTop: 10, display: 'flex', flexDirection: isNonMobile ? 'row' : 'column' }}>
                                                        <Grid item xs={12} sm={12} md={3} style={{ height: '100%', paddingRight: isNonMobile && 10, width: '100%' }}>

                                                            <CardRoot elevation={6} >
                                                                {/* <div>
                                                                <CardTitle style={{ textAlign: 'center', paddingTop: 8, fontSize: 13 }}>Applied on - {(postDetails !== null && postDetails !== undefined) && postDetails.length}</CardTitle>
                                                            </div> */}
                                                                {(allDetails.singleUserData.coverImage !== null && allDetails.singleUserData.coverImage !== '' && allDetails.singleUserData.coverImage !== undefined) ?

                                                                    <a onClick={() => handleShow(allDetails.singleUserData.coverImage)} >

                                                                        <img src={allDetails.singleUserData.coverImage} style={{ height: '50%', width: '100%', objectFit: 'cover' }} />
                                                                    </a>
                                                                    :
                                                                    <a>

                                                                        <img src={CoverDefault_Logo} style={{ height: '180px', width: '100%', objectFit: '' }} />
                                                                    </a>

                                                                }
                                                                {(allDetails.singleUserData.profileImage != undefined && allDetails.singleUserData.profileImage !== null && allDetails.singleUserData.profileImage !== '')
                                                                    ?
                                                                    <div style={{ textAlign: 'center', marginTop: -40 }}>
                                                                        <a onClick={() => handleShow(allDetails.singleUserData.profileImage)}>
                                                                            <img className="img-circle" src={allDetails.singleUserData.profileImage} style={{ height: '80px', width: '80px', borderRadius: 40, objectFit: 'cover' }} />
                                                                        </a>
                                                                    </div>
                                                                    :
                                                                    <div style={{ textAlign: 'center', marginTop: -40 }}>
                                                                        <img className="img-circle" src={Default_Logo} style={{ height: '80px', width: '80px', borderRadius: 40, objectFit: 'cover', backgroundColor: '#FFFF' }} />
                                                                        {/* <CgProfile size={40} color={"#696969"} style={{ height: '80px', width: '80px', borderRadius: 45, objectFit: 'cover', backgroundColor: '#FFFF' }} /> */}
                                                                    </div>
                                                                }

                                                                <CardTitle style={{ textAlign: 'center', fontSize: 18, paddingTop: 10 }}>{allDetails.singleUserData.firstName} {allDetails.singleUserData.lastName} {isVerified && <VscVerifiedFilled size={28} color={"#87BD47"} />} </CardTitle>
                                                                <CardTitle style={{ textAlign: 'center', fontSize: 18 }}>({allDetails.singleUserData.actorCode}) </CardTitle>
                                                                {(allDetails.singleUserData.mobileNumber !== '' && allDetails.singleUserData.mobileNumber !== undefined && allDetails.singleUserData.mobileNumber !== null) ?
                                                                    <Typography style={{ textAlign: 'center' }}>
                                                                        +{allDetails.singleUserData.phoneCode} {allDetails.singleUserData.mobileNumber}
                                                                    </Typography> :
                                                                    <Typography style={{ textAlign: 'center' }}>
                                                                        -
                                                                    </Typography>

                                                                }
                                                                {allDetails.singleUserData.emailId !== null ?
                                                                    <Typography style={{ textAlign: 'center' }}>
                                                                        {allDetails.singleUserData.emailId}
                                                                    </Typography>
                                                                    :
                                                                    <Typography style={{ textAlign: 'center', color: 'grey' }}>
                                                                        - -
                                                                    </Typography>
                                                                }
                                                                <CardTitle style={{ textAlign: 'center', fontSize: 14, fontStyle: 'italic' }}>{(allDetails.listUserAddressData !== undefined && allDetails.listUserAddressData !== null && allDetails.listUserAddressData !== '') && allDetails.listUserAddressData[0].city}</CardTitle>
                                                                <div style={{ textAlign: 'center' }}>
                                                                    {(allDetails.singleUserData.facebookURL !== '' && allDetails.singleUserData.facebookURL !== null && allDetails.singleUserData.facebookURL !== undefined) &&
                                                                        <a target="_blank" href={"https://www.facebook.com/" + allDetails.singleUserData.facebookURL} data-toggle="tooltip" data-placement="top" title="Facebook">
                                                                            <img className="ml-1 mr-2" style={{ width: '9%' }} src={Fb_Logo} />
                                                                        </a>
                                                                    }
                                                                    {
                                                                        (allDetails.singleUserData.instagramId !== '' && allDetails.singleUserData.instagramId !== null && allDetails.singleUserData.instagramId !== undefined) &&
                                                                        <a target="_blank" href={"https://www.instagram.com/" + allDetails.singleUserData.instagramId} data-toggle="tooltip" data-placement="top" title="Instagram">
                                                                            <img className="ml-1 mr-2" style={{ width: '9%', marginLeft: 10 }} src={Insta_Logo} />
                                                                        </a>

                                                                    }
                                                                    {
                                                                        (allDetails.singleUserData.twitterURL !== '' && allDetails.singleUserData.twitterURL !== null && allDetails.singleUserData.twitterURL !== undefined) &&
                                                                        <a target="_blank" href={"https://www.twitter.com/" + allDetails.singleUserData.twitterURL} data-toggle="tooltip" data-placement="top" title="Twitter">
                                                                            <img className="ml-1 mr-2" style={{ width: '10%', marginLeft: 10 }} src={x_Twitter_Logo} />
                                                                        </a>
                                                                    }
                                                                    {
                                                                        (allDetails.singleUserData.youTubeId !== '' && allDetails.singleUserData.youTubeId !== null && allDetails.singleUserData.youTubeId !== undefined) &&
                                                                        <a target="_blank" href={allDetails.singleUserData.youTubeId} data-toggle="tooltip" data-placement="top" title="Youtube">
                                                                            <img className="ml-1 mr-2" style={{ width: '10%', marginLeft: 10 }} src={YT_Logo} />
                                                                        </a>
                                                                    }
                                                                    {(allDetails.singleUserData.imdbProfile !== '' && allDetails.singleUserData.imdbProfile !== null && allDetails.singleUserData.imdbProfile !== undefined) &&
                                                                        <a target="_blank" href={allDetails.singleUserData.imdbProfile} data-toggle="tooltip" data-placement="top" title="IMDb">
                                                                            <img className="ml-1 mr-2" style={{ width: '9%', marginLeft: 10 }} src={IMDb_Logo} />
                                                                        </a>
                                                                    }
                                                                    {(allDetails.singleUserData.otherProfile !== '' && allDetails.singleUserData.otherProfile !== null && allDetails.singleUserData.otherProfile !== undefined) &&
                                                                        <a target="_blank" href={allDetails.singleUserData.otherProfile} data-toggle="tooltip" data-placement="top" title="Other">
                                                                            <img className="ml-1 mr-2" style={{ width: '9%', marginLeft: 10 }} src={Cloud_Logo} />
                                                                        </a>
                                                                    }
                                                                    {(allDetails.singleUserData.monologueLink !== '' && allDetails.singleUserData.monologueLink !== null && allDetails.singleUserData.monologueLink !== undefined) &&
                                                                        <a target="_blank" href={allDetails.singleUserData.monologueLink} data-toggle="tooltip" data-placement="top" title="Monologue">
                                                                            <img className="ml-1 mr-1" style={{ width: '11%', marginLeft: 10 }} src={Audition_Icon} />
                                                                        </a>
                                                                    }
                                                                </div>
                                                                <div style={{ padding: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                <CardTitle style={{ fontSize: 16, paddingTop: 0, marginBottom: 0, display: 'flex', justifyContent: 'center' }}>Bio</CardTitle>
                                                                <div style={{ padding: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>

                                                                {(allDetails.singleUserData.biography !== '' && allDetails.singleUserData.biography !== undefined && allDetails.singleUserData.biography !== null) ?

                                                                    <Typography style={{ padding: 15, textAlign: 'center' }}>
                                                                        {allDetails.singleUserData.biography}
                                                                    </Typography>
                                                                    :
                                                                    <Typography style={{ padding: 15, textAlign: 'center', color: 'grey' }}>
                                                                        - -
                                                                    </Typography>

                                                                }
                                                                <div style={{ padding: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                <CardTitle style={{ fontSize: 16, paddingTop: 0, marginBottom: 0, display: 'flex', justifyContent: 'center' }}>Skills</CardTitle>
                                                                <div style={{ padding: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                {(allDetails.singleUserData.skills !== '' && allDetails.singleUserData.skills !== undefined && allDetails.singleUserData.skills !== null) ?

                                                                    <Typography style={{ padding: 15, textAlign: 'center' }}>
                                                                        {allDetails.singleUserData.skills}
                                                                    </Typography>
                                                                    :
                                                                    <Typography style={{ padding: 15, textAlign: 'center', color: 'grey' }}>
                                                                        - -
                                                                    </Typography>

                                                                }
                                                                {((allDetails.singleUserData.introVideo !== '' && allDetails.singleUserData.introVideo !== undefined && allDetails.singleUserData.introVideo !== null) || (allDetails.singleUserData.uploadIntroVideo !== '' && allDetails.singleUserData.uploadIntroVideo !== undefined && allDetails.singleUserData.uploadIntroVideo !== null)) &&
                                                                    <>
                                                                        <div style={{ padding: 10 }}>
                                                                            <Divider style={{ backgroundColor: 'black' }} />
                                                                        </div>
                                                                        <div style={{ textAlign: 'center', paddingBottom: 10, paddingTop: 10 }}>
                                                                            {
                                                                                (allDetails.singleUserData.introVideo !== '' && allDetails.singleUserData.introVideo !== undefined && allDetails.singleUserData.introVideo !== null) &&
                                                                                <a target="_blank" href={allDetails.singleUserData.introVideo} >
                                                                                    <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginBottom: 5 }} >
                                                                                        <b>Intro Link</b>
                                                                                    </Button>
                                                                                </a>
                                                                            }
                                                                            {
                                                                                (allDetails.singleUserData.uploadIntroVideo !== '' && allDetails.singleUserData.uploadIntroVideo !== undefined && allDetails.singleUserData.uploadIntroVideo !== null) &&
                                                                                <a target="_blank" href={allDetails.singleUserData.uploadIntroVideo} >
                                                                                    <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginBottom: 5, marginLeft: 5 }} >
                                                                                        <b>Intro Video</b>
                                                                                    </Button>
                                                                                </a>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                }

                                                                {/* {userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1 && */}
                                                                {userFromSession.organizationId === 4 &&
                                                                    <>
                                                                        <div style={{ padding: 10 }}>
                                                                            <Divider style={{ backgroundColor: 'black' }} />
                                                                        </div>
                                                                        <CardTitle style={{ fontSize: 16, paddingTop: 0, marginBottom: 0, display: 'flex', justifyContent: 'center' }}>Tags</CardTitle>
                                                                        <div style={{ padding: 10 }}>
                                                                            <Divider style={{ backgroundColor: 'black' }} />
                                                                        </div>

                                                                        {tagsOpen &&
                                                                            <div style={{ textAlign: 'center', paddingBottom: 10, paddingTop: 10 }}>
                                                                                <Button onClick={onClickTags} sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginTop: 5, marginBottom: 5 }} >
                                                                                    Add tags
                                                                                </Button>
                                                                            </div>}
                                                                        {tagFields &&
                                                                            <Grid container spacing={{ xs: 2, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ padding: 20 }}>
                                                                                <Grid item xs={12}>
                                                                                    <Autocomplete
                                                                                        multiple={false}
                                                                                        id="tags-outlined-area"
                                                                                        options={areaTypes}
                                                                                        getOptionLabel={(option) => {
                                                                                            return option?.label != undefined ? option.label : ''
                                                                                        }}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                inputProps={{ ...params.inputProps, tabIndex: 3 }}
                                                                                                variant="outlined"
                                                                                                label="Select Area Type"
                                                                                                placeholder="Select Area Type"
                                                                                                fullWidth
                                                                                            />
                                                                                        )}
                                                                                        style={{ marginBottom: 16 }}
                                                                                        value={areaValue}
                                                                                        onChange={(_, newValue) => {
                                                                                            setAreaValue(newValue);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12}>
                                                                                    <Autocomplete
                                                                                        multiple={false}
                                                                                        id="tags-outlined-age"
                                                                                        options={ageCategory}
                                                                                        getOptionLabel={(option) => {
                                                                                            return option?.label != undefined ? option.label : ''
                                                                                        }}
                                                                                        renderInput={(params) => (
                                                                                            <TextField
                                                                                                {...params}
                                                                                                inputProps={{ ...params.inputProps, tabIndex: 3 }}
                                                                                                variant="outlined"
                                                                                                label="Select Age Category"
                                                                                                placeholder="Select Age Category"
                                                                                                fullWidth
                                                                                            />
                                                                                        )}
                                                                                        style={{ marginBottom: 16 }}
                                                                                        value={ageValue}
                                                                                        onChange={(_, newValue) => {
                                                                                            setAgeValue(newValue);
                                                                                        }}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} style={{ marginTop: 0 }}>
                                                                                    <Stack spacing={2} direction="row-reverse" >
                                                                                        <Button variant="outlined" sx={{ ":hover": { borderColor: "#ea232a" }, borderColor: '#e0746e', color: '#e0746e' }} onClick={onClickCancelTags}>Cancel</Button>
                                                                                        <Button variant="contained" sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} onClick={submitTags}> {editTag ? 'Update' : 'Submit'}</Button>
                                                                                    </Stack>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                        {
                                                                            displayFields &&
                                                                            <Grid container spacing={{ xs: 2, md: 1 }} style={{ padding: 20 }}>
                                                                                <Grid item xs={10}>
                                                                                    <Typography>Area Type :- {areaValue !== null && areaValue !== undefined ? areaValue.label : ''}</Typography>
                                                                                    <Typography>Age Category :- {ageValue !== null && ageValue !== undefined ? ageValue.label : ''}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <IconButton sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} className="button" aria-label="Update" onClick={() => onClickEdit()}>
                                                                                        <Icon>edit</Icon>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                            </Grid>
                                                                        }
                                                                    </>
                                                                }
                                                            </CardRoot>
                                                            <CardRoot elevation={6} style={{ marginTop: 10 }}>

                                                                <CardTitle style={{ fontSize: 16, paddingTop: 10, paddingLeft: 10 }}>Current Address</CardTitle>
                                                                <div style={{ paddingBottom: 10, paddingRight: 10, paddingLeft: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                {
                                                                    (allDetails.listUserAddressData !== undefined && allDetails.listUserAddressData !== null && allDetails.listUserAddressData !== '') ?
                                                                        <>
                                                                            <Typography style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10, color: 'grey' }}>
                                                                                {allDetails.listUserAddressData[0].address1} {allDetails.listUserAddressData[0].address2 == '' ? '' : ','} {allDetails.listUserAddressData[0].address2}
                                                                            </Typography>
                                                                            <Typography style={{ paddingLeft: 10, paddingBottom: 10 }}> {allDetails.listUserAddressData[0].city} - {allDetails.listUserAddressData[0].postalCode !== '' && allDetails.listUserAddressData[0].postalCode}, {allDetails.listUserAddressData[0].state}, {allDetails.listUserAddressData[0].country}</Typography>
                                                                        </>
                                                                        :
                                                                        <Typography style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10, color: 'grey' }}>
                                                                            --
                                                                        </Typography>
                                                                }


                                                                <Divider style={{ backgroundColor: 'black' }} />
                                                                <CardTitle style={{ fontSize: 16, paddingTop: 10, paddingLeft: 10, marginBottom: 0 }}>Permanent Address</CardTitle>
                                                                <div style={{ marginTop: "8px", paddingRight: 10, paddingLeft: 10 }}>

                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                {
                                                                    (allDetails.listUserAddressData !== undefined && allDetails.listUserAddressData !== null && allDetails.listUserAddressData !== '') ?
                                                                        <>
                                                                            <Typography style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10, color: 'grey' }}>
                                                                                {allDetails.listUserAddressData[0].pAddress1} {allDetails.listUserAddressData[0].pAddress2 == '' ? '' : ','} {allDetails.listUserAddressData[0].pAddress2}
                                                                            </Typography>
                                                                            <Typography style={{ paddingLeft: 10, paddingBottom: 10 }}> {allDetails.listUserAddressData[0].pCity} - {allDetails.listUserAddressData[0].pPostalCode !== '' && allDetails.listUserAddressData[0].pPostalCode}, {allDetails.listUserAddressData[0].pState}, {allDetails.listUserAddressData[0].pCountry}</Typography>
                                                                        </>
                                                                        :
                                                                        <Typography style={{ display: 'flex', justifyContent: 'center', paddingTop: 10, paddingBottom: 10, color: 'grey' }}>
                                                                            --
                                                                        </Typography>
                                                                }

                                                            </CardRoot>
                                                            <CardRoot elevation={6} style={{ marginTop: 10 }}>

                                                                <CardTitle style={{ fontSize: 16, paddingTop: 10, paddingLeft: 10 }}>Verification</CardTitle>
                                                                <div style={{ paddingBottom: 10, paddingRight: 10, paddingLeft: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>

                                                                {isVerified === false ?
                                                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                                                        <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => onClickVerification()}>
                                                                            <b>Verify</b>
                                                                        </Button>
                                                                    </div>
                                                                    :
                                                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                                                        <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => onClickRemoveVerification()}>
                                                                            <b>Rovoke Verify</b>
                                                                        </Button>
                                                                    </div>
                                                                }

                                                                <div style={{ paddingBottom: 10, paddingRight: 10, paddingLeft: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                <CardTitle style={{ fontSize: 16, paddingTop: 10, paddingLeft: 10 }}>Deactivate Account</CardTitle>
                                                                <div style={{ paddingBottom: 10, paddingRight: 10, paddingLeft: 10 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>

                                                                {isDeactivated === false ?
                                                                    <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10 }}>
                                                                        <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginBottom: 5, marginLeft: 5 }} onClick={() => onClickDeactivateAccount()}>
                                                                            <b>Deactivate</b>
                                                                        </Button>
                                                                    </div>
                                                                    :
                                                                    <div style={{ display: 'flex', paddingBottom: 10, marginLeft: 10 }}>
                                                                        <FcApproval size={20} color={"#696969"} /> This account has been deactivated.
                                                                    </div>
                                                                }


                                                            </CardRoot>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={9} style={{ height: '100%', paddingRight: isNonMobile && 10, paddingTop: isNonMobile == false && 10 }}>
                                                            <CardRoot elevation={6} style={{ padding: '10px' }}>
                                                                <Typography style={{ paddingBottom: 9 }}>{allDetails.listUserImageGallery !== null ? allDetails.listUserImageGallery.length : '0'} Photos</Typography>
                                                                <div className="filter-container p-0 row">


                                                                    {galleryImg !== null && galleryImg.map((el, index) => {
                                                                        return (
                                                                            <div key={index} className="filtr-item col" data-category="" data-sort="white sample" style={{ textAlign: 'center' }}>
                                                                                {
                                                                                    el.imageURL !== '' &&
                                                                                    <a onClick={() => { handleShow(el.imageURL) }}>
                                                                                        {isNonMobile ?
                                                                                            <img src={el.imageURL} style={{ height: '300px', width: '100%', objectFit: 'cover' }} />
                                                                                            :
                                                                                            <img src={el.imageURL} style={{ height: '100px', width: '100%', objectFit: 'cover' }} />
                                                                                        }
                                                                                    </a>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                <Grid direction="row" container spacing={{ xs: 1, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                                    <Grid item xs={1}>
                                                                        {allDetails.singleUserData.gender !== null &&
                                                                            allDetails.singleUserData.gender === 1 ?
                                                                            <Typography style={{ fontWeight: 'bold' }}>Female</Typography>
                                                                            :
                                                                            allDetails.singleUserData.gender === 0 ?
                                                                                <Typography style={{ fontWeight: 'bold' }}>Male</Typography>
                                                                                :
                                                                                <Typography style={{ fontWeight: 'bold' }}>Others</Typography>
                                                                        }
                                                                        <Typography>Gender</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {
                                                                            (allDetails.singleUserData.dob !== '' && allDetails.singleUserData.dob !== undefined && allDetails.singleUserData.dob !== null) ?
                                                                                <Typography style={{ fontWeight: 'bold' }}>
                                                                                    {moment(allDetails.singleUserData.dob).format('DD MMM YYYY')} ({allDetails.singleUserData.age} yrs)
                                                                                </Typography>
                                                                                :
                                                                                <Typography style={{ fontWeight: 'bold' }}>
                                                                                    --
                                                                                </Typography>
                                                                        }
                                                                        <Typography>Date of birth</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {(allDetails.singleUserData.height !== '' && allDetails.singleUserData.height !== undefined && allDetails.singleUserData.height !== null) ?
                                                                            <Typography style={{ fontWeight: 'bold' }}>{cmToFeetInches(allDetails.singleUserData.height)}</Typography> :

                                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                                --
                                                                            </Typography>
                                                                        }
                                                                        <Typography>Height</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                        {(allDetails.singleUserData.weight !== '' && allDetails.singleUserData.weight !== undefined && allDetails.singleUserData.weight !== null) ?
                                                                            <Typography style={{ fontWeight: 'bold' }}>{allDetails.singleUserData.weight} Kg</Typography> :
                                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                                --
                                                                            </Typography>

                                                                        }
                                                                        <Typography>Weight</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2}>
                                                                        {(allDetails.singleUserData.fatherName !== '' && allDetails.singleUserData.fatherName !== undefined && allDetails.singleUserData.fatherName !== null) ?
                                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                                {allDetails.singleUserData.fatherName}
                                                                            </Typography> :
                                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                                --
                                                                            </Typography>
                                                                        }
                                                                        <Typography>Middle Name</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <Stack direction={"row"} style={{ paddingRight: 30, display: 'flex', flexWrap: 'wrap' }}>
                                                                            {allDetails.listUserLanguageData !== null &&
                                                                                allDetails.listUserLanguageData.map((el, index) => {
                                                                                    return (
                                                                                        <Stack key={index} direction={'row'}>
                                                                                            <div>
                                                                                                {(el.languageName !== null && el.languageName !== '') ?

                                                                                                    <Typography style={{ fontWeight: 'bold', marginRight: 5 }}>{el.languageName}{allDetails.listUserLanguageData.length - 1 && allDetails.listUserLanguageData.length - 1 != index ? ', ' : null}</Typography>
                                                                                                    :
                                                                                                    <Typography style={{ fontWeight: 'bold' }}>
                                                                                                        --
                                                                                                    </Typography>
                                                                                                }
                                                                                            </div>
                                                                                        </Stack>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Stack>
                                                                        <Stack>
                                                                            <Typography>Language Fluency</Typography>
                                                                        </Stack>
                                                                    </Grid>
                                                                </Grid>

                                                            </CardRoot>
                                                            <CardRoot elevation={6} style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10, marginTop: 10 }}>
                                                                <CardTitle style={{ fontSize: 16, paddingTop: 10 }}>Work Experience</CardTitle>
                                                                <div style={{ paddingBottom: 10 }}>

                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                    {allDetails.listWorkExpURL !== null ?

                                                                        allDetails.listWorkExpURL.map((el, index) => {
                                                                            return (
                                                                                <div className="row" key={index} style={{ width: isNonMobile ? '25%' : '100%', marginRight: 10, padding: 10 }}>
                                                                                    <SimpleCard>
                                                                                        <Typography style={{ fontWeight: 'bold' }}>{el.workExpCategoryName}</Typography>
                                                                                        <Typography>
                                                                                            {el.videoTitle}
                                                                                        </Typography>
                                                                                        {(el.videoURL !== '' && el.videoURL !== null) &&
                                                                                            <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                                                                                                <a href={el.videoURL} target="_blank" style={{ display: 'flex', flexDirection: "row" }}>
                                                                                                    <div style={{ marginRight: 5 }}>
                                                                                                        <AiOutlineLink size={16} />
                                                                                                    </div>
                                                                                                    <span style={{ display: 'flex', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block' }}>
                                                                                                        {el.videoURL}
                                                                                                    </span>
                                                                                                </a>
                                                                                            </div>}
                                                                                    </SimpleCard>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        :
                                                                        <Typography style={{ fontWeight: 'bold', paddingBottom: 10 }}>
                                                                            No record exist
                                                                        </Typography>
                                                                    }

                                                                </div>
                                                            </CardRoot>
                                                            <CardRoot elevation={6} style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10, marginTop: 10 }}>
                                                                <CardTitle style={{ fontSize: 16, paddingTop: 10 }}>Education Data</CardTitle>
                                                                <div style={{ paddingBottom: 10 }}>

                                                                    <Divider style={{ backgroundColor: 'black' }} />
                                                                </div>
                                                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                                    {(educationData !== null && educationData !== undefined && educationData.length > 0) || allDetails.listUserEducationData !== null ?
                                                                        <>
                                                                            {
                                                                                educationData.map((el, index) => {
                                                                                    return (
                                                                                        <div key={index} style={{ paddingTop: 10, width: isNonMobile ? "25%" : '100%', padding: 5 }}>
                                                                                            <CardRoot style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}>

                                                                                                <Typography style={{ fontWeight: 'bold', paddingTop: 5, paddingBottom: 5 }}>{el.educationType === 1 ? 'Acting Training' : el.educationType === 2 ? 'Dancing Training' : 'Diction Training'}</Typography>
                                                                                                <Divider style={{ backgroundColor: 'grey', marginBottom: 5 }} />
                                                                                                {
                                                                                                    el.trainingInstName.map((e, i) => {
                                                                                                        return (
                                                                                                            <div key={i}>
                                                                                                                {e !== '' && <li>
                                                                                                                    {e}
                                                                                                                </li>}
                                                                                                            </div>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </CardRoot>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                            {allDetails.listUserEducationData &&
                                                                                allDetails.listUserEducationData.map((el, index) => {
                                                                                    return (
                                                                                        <div key={index} style={{ paddingTop: 10, width: isNonMobile ? "25%" : '100%', padding: 5 }}>
                                                                                            <CardRoot style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                                                                <Typography style={{ fontWeight: 'bold', paddingTop: 5, paddingBottom: 5 }}>Education Details</Typography>
                                                                                                <Divider style={{ backgroundColor: 'grey', marginBottom: 5 }} />
                                                                                                {/* <Typography style={{ fontWeight: 'bold' }}>
                                                                                                    {el.schoolName}
                                                                                                </Typography> */}
                                                                                                <Typography>{el.qualificationName}</Typography>

                                                                                                {/* {el.passingYear !== null ?
                                                                                                    <Typography>
                                                                                                        {el.passingYear}
                                                                                                    </Typography>
                                                                                                    :
                                                                                                    <p>-</p>

                                                                                                }
                                                                                                <Typography>
                                                                                                    {el.course}
                                                                                                </Typography> */}
                                                                                            </CardRoot>
                                                                                        </div>
                                                                                    )
                                                                                })

                                                                            }
                                                                        </>
                                                                        :
                                                                        <Typography style={{ fontWeight: 'bold', paddingBottom: 10 }}>
                                                                            No record exist
                                                                        </Typography>
                                                                    }


                                                                    {/* {allDetails.listUserEducationData !== null &&

                                                                        allDetails.listUserEducationData.map((el, index) => {
                                                                            return (
                                                                                <div key={index} style={{ paddingTop: 10, width: isNonMobile ? "25%" : '100%', padding: 5 }}>
                                                                                    <CardRoot style={{ padding: 10, paddingTop: 5, paddingBottom: 5 }}>
                                                                                        <Typography style={{ fontWeight: 'bold', paddingTop: 5, paddingBottom: 5 }}>Education Details</Typography>
                                                                                        <Divider style={{ backgroundColor: 'grey', marginBottom: 5 }} />
                                                                                        <Typography style={{ fontWeight: 'bold' }}>
                                                                                            {el.schoolName}
                                                                                        </Typography>
                                                                                        <Typography>{el.qualificationName}</Typography>

                                                                                        {el.passingYear !== null ?
                                                                                            <Typography>
                                                                                                {el.passingYear}
                                                                                            </Typography>
                                                                                            :
                                                                                            <p>-</p>

                                                                                        }
                                                                                        <Typography>
                                                                                            {el.course}
                                                                                        </Typography>
                                                                                    </CardRoot>
                                                                                </div>
                                                                            )
                                                                        })

                                                                    } */}

                                                                </div>
                                                            </CardRoot>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>
                                            </Grid>
                                            {show == true && <ModalView show={show} handleClose={handleClose} imgUrl={img} />}
                                            {confirmModal &&
                                                <Dialog open={confirmModal}
                                                    // fullWidth={true}
                                                    // maxWidth={'md'}
                                                    // style={{ height: '25%' }}
                                                    onClose={() => setConfirmModal(true)}
                                                    aria-labelledby="form-dialog-title"
                                                >
                                                    <DialogTitle style={{ paddingBottom: 5, alignSelf: 'center' }}>{`Are you sure you want to ${isVerified === false ? 'verify' : 'revoke verification for'} this actor?`}</DialogTitle>
                                                    <DialogContent>
                                                        <Stack direction={'column'}>
                                                            <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'center', marginTop: 2 }} >
                                                                <>
                                                                    <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" type="submit" size='large' onClick={() => { onClickYesVerification(); }}>
                                                                        <Span sx={{ textTransform: "capitalize" }}>Yes</Span>
                                                                    </Button>
                                                                    <Button sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" type="submit" size='large' onClick={() => onClickNoVerification()}>
                                                                        <Span sx={{ textTransform: "capitalize" }}>No</Span>
                                                                    </Button>
                                                                </>

                                                            </Stack>
                                                        </Stack>
                                                        {/* } */}
                                                    </DialogContent>
                                                </Dialog >
                                            }
                                            {deactivateModal &&
                                                <Dialog open={deactivateModal}
                                                    // fullWidth={true}
                                                    // maxWidth={'md'}
                                                    // style={{ height: '25%' }}
                                                    onClose={() => setDeactivateModal(true)}
                                                    aria-labelledby="form-dialog-title"
                                                >
                                                    <DialogTitle style={{ paddingBottom: 5, alignSelf: 'center' }}>{`Are you sure you want to deactivate this actor?`}</DialogTitle>
                                                    <DialogContent>
                                                        <Stack direction={'column'}>
                                                            <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'center', marginTop: 2 }} >
                                                                <>
                                                                    <Button color="primary" variant="contained" type="submit" size='large' onClick={() => { onClickYesDeactivate(); }}>
                                                                        <Span sx={{ textTransform: "capitalize" }}>Yes</Span>
                                                                    </Button>
                                                                    <Button color="primary" variant="contained" type="submit" size='large' onClick={() => onClickNoDeactivate()}>
                                                                        <Span sx={{ textTransform: "capitalize" }}>No</Span>
                                                                    </Button>
                                                                </>

                                                            </Stack>
                                                        </Stack>
                                                        {/* } */}
                                                    </DialogContent>
                                                </Dialog >
                                            }
                                        </Stack>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <SimpleCard customStyle={{ paddingLeft: 0, paddingRight: 0, marginLeft: -10 }}>
                                            <PostTableLayout
                                                columns={columns}
                                                conditionalRowStyles={conditionalRowStyles}
                                                posts={postDetails}
                                                pointerOnHover={false}
                                            />
                                        </SimpleCard>
                                        {
                                            openStageDialog &&
                                            <PopUpModal
                                                openStageDialog={openStageDialog}
                                                selectedRowData={selectedRowData}
                                                onSubmitStageData={onSubmitStageData}
                                                handleCloseDialog={handleCloseDialog}
                                                firstName={allDetails.singleUserData.firstName}
                                                lastName={allDetails.singleUserData.lastName}
                                                userId={allDetails.singleUserData.userId}
                                            />
                                        }
                                    </TabPanel>
                                </TabContext>
                                {openSnackBar &&
                                    <CustomSnackBar
                                        openSnackBar={openSnackBar}
                                        handleCloseSnackBar={handleCloseSnackBar}
                                        severity="success"
                                        message={openSnackBarMsg}
                                    />
                                }
                                {
                                    popUp &&
                                    <ValidationPopUp
                                        openModal={popUp}
                                        handleClose={() => setPopUp(false)}
                                        contentText={'Please select atleast one field'}
                                    />
                                }
                                {errorPopUp == true &&
                                    <ServerErrorModal
                                        popUpFor={errorMsg}
                                        openAlert={errorPopUp}
                                        handleCloseAlert={handleCloseAlert}
                                    />
                                }
                            </Box>
                        </>

                }
            </Container >
        </DocumentTitle >

    );
}

export default UserDetails;
