import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const CreatPost = Loadable(lazy(() => lazyRetry(() => import('./CreatePost'), "createPost")));
const CreatProject = Loadable(lazy(() => lazyRetry(() => import('./CreateProject'), "createProject")));
const ListPost = Loadable(lazy(() => lazyRetry(() => import('./ListPost'), "listPost")));
const DetailPost = Loadable(lazy(() => lazyRetry(() => import('./DetailPost'), "detailPost")));

const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh

        });
    });
};

const postRoutes = [
    {
        path: '/post/CreatePost', element: <CreatPost />,
    },
    {
        path: '/post/CreateProject', element: <CreatProject />,
    },
    {
        path: '/post/listPost', element: <ListPost />,
    },
    {
        path: '/post/detailPost', element: <DetailPost />,
    }
];

export default postRoutes;