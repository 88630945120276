import AuthGuard from "app/auth/AuthGuard";
import chartsRoute from "app/views/charts/ChartsRoute";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { Navigate, useParams } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import postRoutes from "./views/post/PostRoutes";
import profileRoutes from "./views/profile/ProfileRoutes";
import userRoutes from "./views/users/UserRoutes";
import UserDetails from "./views/users/UserDetails";
import PrivacyPolicy from "./views/singlepage/PrivacyPolicy";
import TermsCondition from "./views/singlepage/TermsCondition";
// import AdminLogin from "./views/singlepage/AdminLogin";

//<-----Navigation Details Logic In progress---->

// const nav = () => {
//   // const { id } = useParams()
//   let sessionData = JSON.parse(window.sessionStorage.getItem("dataUserId"))
//   console.log(`---------sessionData =  `, sessionData)
//   // if (id) {
//   //   const routes = [
//   //     {
//   //       element: (
//   //         <AuthGuard>
//   //           <MatxLayout />
//   //         </AuthGuard>
//   //       ),
//   //       children: [...dashboardRoutes, ...chartsRoute, ...materialRoutes, ...userRoutes, ...postRoutes, ...profileRoutes],
//   //     },
//   //     ...sessionRoutes,

//   //     // { path: '/', element: <Navigate to="dashboard" /> },
//   //     { path: '/', element: id ? <Navigate to="/users/UserDetails/10677" /> : <Navigate to="dashboard" /> },
//   //     // { path: '/', element: <Navigate to="/users/UserList" /> },
//   //     // { path: '/', element: <Navigate to="signin-oidc" /> },
//   //     { path: '*', element: <NotFound /> },
//   //     // { path: '/detailPost', element: <Navigate to="DetailPost" /> },
//   //   ];
//   //   return routes;
//   // } else {
//   const routes = [
//     {
//       element: (
//         <AuthGuard>
//           <MatxLayout />
//         </AuthGuard>
//       ),
//       children: [...dashboardRoutes, ...chartsRoute, ...materialRoutes, ...userRoutes, ...postRoutes, ...profileRoutes],
//     },
//     ...sessionRoutes,

//     // { path: '/', element: <Navigate to="dashboard" /> },
//     { path: '/', element: <Navigate to="/users/UserDetails/10677" /> },
//     // { path: '/', element: id ? <Navigate to="/users/UserDetails/10677" /> : <Navigate to="dashboard" /> },
//     // { path: '/', element: <Navigate to="/users/UserList" /> },
//     // { path: '/', element: <Navigate to="signin-oidc" /> },
//     { path: '*', element: <NotFound /> },
//     // { path: '/detailPost', element: <Navigate to="DetailPost" /> },
//   ];
//   return routes;
//   //   }
// }

// export default nav;

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...userRoutes,
      ...postRoutes,
      ...profileRoutes,
    ],
  },
  ...sessionRoutes,

  // { path: '/', element: <Navigate to={window.location.href === 'http://localhost:3000/users/UserDetails/10786' ? "/users/UserDetails/10786" : "dashboard"} /> },
  // { path: '/', element: <Navigate to="/users/UserDetails/10786" /> },
  { path: "/", element: <Navigate to="dashboard" /> },
  // { path: "/dashboard", element: <Navigate to="dashboard" /> },
  // { path: '/', element: <Navigate to="signin-oidc" /> },
  { path: "*", element: <NotFound /> },
  { path: "/privacypolicy", element: <PrivacyPolicy /> },
  { path: "/termsofservice", element: <TermsCondition /> },
  // { path: "/adminlogin", element: <Navigate to="dashboard" /> },

  // { path: '/detailPost', element: <Navigate to="DetailPost" /> },
];
// const routes1 = [
//   {
//     element: (
//       <AuthGuard>
//         <MatxLayout />
//       </AuthGuard>
//     ),
//     children: [...dashboardRoutes, ...chartsRoute, ...materialRoutes, ...userRoutes, ...postRoutes, ...profileRoutes],
//   },
//   ...sessionRoutes,

//   { path: '/', element: <Navigate to="/users/UserDetails/10786" /> },
//   // { path: '/', element: <Navigate to="/users/UserDetails/10786" /> },
//   // { path: '/', element: <Navigate to="signin-oidc" /> },
//   { path: '*', element: <NotFound /> }
//   // { path: '/detailPost', element: <Navigate to="DetailPost" /> },
// ];

// export { routes, routes1 };
export default routes;
