import {
    Box,
    Icon,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import moment from "moment";
import { SimpleCard } from "app/components";
import CreateUpdatePost from "./Post/CreateUpdatePost";
import UpdatePost from "app/views/material-kit/dialog/UpdatePost";
import { catchError } from "app/firebase/Crashlytics";

const PostTableLayout = ({ posts, columns, openUpdateModal, onClose, postId, pointerOnHover, onRowClicked, pending, onSubmit, handlePageChange, handlePerRowsChange, paginationTotalRows, paginationResetDefaultPage, conditionalRowStyles, validTill }) => {

    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState([]);
    // const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [singlePostData, setSinglePostData] = useState()

    function handleClose() {
        // setOpenUpdateModal(false);
        setOpenViewModal(false);
    }



    const customStyles = {
        rows: {
            style: {
                // minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingTop: '8px',
                paddingTop: '8px'
                // paddingLeft: '8px', // override the cell padding for head cells
                // paddingRight: '8px'
            },
        },
        cells: {
            style: {
                paddingTop: '8px', // override the cell padding for data cells
                paddingBottom: '8px',
            },
        },
    };
    const onClickEdit = (row) => {
        // console.log('Row ==> ', row)
        setSinglePostData(row);
        // setOpenUpdateModal(true);
    };

    const onClickView = (row) => {
        // setOpenUpdateModal(false);
        // setOpenViewModal(true);
        setSinglePostData(row);
        navigate('/post/DetailPost', { state: { postData: row } });
    }

    const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

    const handleRowSelected = useCallback(state => {
        try {
            setSelectedRows(state.selectedRows);
        } catch (error) {
            catchError(error, "PostTableLayout_1");
        }
    }, []);

    return (
        <>
            <Box width="100%" >
                {/* {renderTableLayout_old()} */}
                <DataTable
                    columns={columns}
                    data={posts}
                    // selectableRows
                    // selectableRowsComponentProps={selectProps}
                    onSelectedRowsChange={handleRowSelected}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                    highlightOnHover
                    pointerOnHover={pointerOnHover}
                    onRowClicked={onRowClicked}
                    progressPending={pending}
                    paginationTotalRows={paginationTotalRows}
                    // dense
                    conditionalRowStyles={conditionalRowStyles}
                    pagination
                    paginationServer
                    paginationResetDefaultPage={paginationResetDefaultPage}
                    responsive
                    // progressPending={''}
                    customStyles={customStyles}
                    dense
                    progressComponent={
                        // <CircularProgress
                        //   style={{ backgroundColor: '#FFF', marginTop: 20, marginBottom: 40 }}
                        //   className="progress" />
                        // <img src="/assets/images/loader.svg" alt="" style={{ height: 100, width: 100, marginTop: 20, marginBottom: 40 }} />
                        <img src="/assets/images/reel.gif" alt="" style={{ height: 80, width: 80, marginTop: 20, marginBottom: 40 }} />
                    }
                />
            </Box>
            {/* {openUpdateModal && */}
            {
                openUpdateModal &&
                <div>
                    <UpdatePost
                        openPopUp={openUpdateModal}
                        validTillExp={validTill}
                        onClose={onClose}
                        postId={postId}
                        onSubmit={onSubmit}
                    />
                </div>
            }
        </>
    );
};

export default PostTableLayout;  