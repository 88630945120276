
import { Alert, Snackbar } from "@mui/material";
import PropTypes from 'prop-types';

const CustomSnackBar = ({
    openSnackBar,
    handleCloseSnackBar,
    severity,
    message
}) => {
    return (
        <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={handleCloseSnackBar} severity={severity} sx={{ width: "100%" }} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackBar;

CustomSnackBar.propTypes = {
    openSnackBar: PropTypes.bool,
    handleCloseSnackBar: PropTypes.func,
    message: PropTypes.string,
    severity: PropTypes.string,
};

CustomSnackBar.defaultProps = {
    openSnackBar: false,
    handleCloseSnackBar: () => { },
    message: "",
    severity: 'success'
};

