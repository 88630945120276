import {
    IconButton,
    Button,
    TextField,
    Autocomplete,
    DialogTitle,
    DialogContentText,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    styled,
    Grid,
    useMediaQuery,
    CircularProgress
} from '@mui/material';
import { auditionTypes, stageNotifMsgOnHold, stageNotifMsgRejected, stageNotifMsgSeleceted, stagesListData, stageStatus, yrfStagesListData } from 'app/constant';
import DataTable from 'react-data-table-component';
import CloseIcon from '@mui/icons-material/Close';
import { endPointName } from 'apis/apiList';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { CallAPI } from 'apis/callAPI';
import { catchError } from 'app/firebase/Crashlytics';
import ValidationPopUp from './ValidationPopUp';
import useAuth from 'app/hooks/useAuth';
import ServerErrorModal from './ServerErrorModal';

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(1),
}));

export const PopUpModal = ({
    openStageDialog,
    handleCloseDialog,
    selectedRowData,
    onSubmitStageData,
    firstName,
    lastName,
    location,
    postData,
    userId
}) => {
    const { logout, user } = useAuth();
    const userFromSession = JSON.parse(sessionStorage.getItem("userJSON"));
    const accessToken = window.localStorage.getItem('accessToken')
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [stage, setStage] = useState(null);
    const [status, setStatus] = useState(null);
    const [auditionTypeValue, setAuditionTypeValue] = useState(null);
    const [remarks, setRemarks] = useState();
    const [historyData, setHistoryData] = useState([]);
    const [isStageError, setIsStageError] = useState(false);
    const [isStatusError, setIsStatusError] = useState(false);
    const [isAuditionTypeError, setIsAuditionTypeError] = useState(false);
    const [isRemarksError, setIsRemarksError] = useState(false);
    const [videoDataList, setVideoDataList] = useState('');
    const [popUp, setPopUp] = useState(false);
    const [APILoading, setAPILoading] = useState(false);
    const [isOnline, setOnline] = useState(true);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const stageHistoryColumns = [

        {
            name: 'Stage',
            selector: row => userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1 ? yrfStagesListData[row.stageId - 1].label : stagesListData[row.stageId - 1].label,
            // width: '30%',
        },
        {
            name: 'Status',
            // selector: row => row.applicationStatus === 0 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label,
            // width: '17%',
            selector: row => {
                return (
                    // <div style={{ borderWidth: 1, backgroundColor: 'blue', paddingLeft: 10, paddingRight: 10, paddingTop: 8, borderRadius: 10 }}>

                    //     <p style={{ color: row.applicationStatus === 1 ? 'green' : row.applicationStatus === 2 ? 'orange' : 'red', marginTop: 2 }}>{row.applicationStatus === 0 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}</p>
                    // </div>
                    <StyledButton variant="outlined" color="primary" disabled={true} style={{ marginLeft: 0, height: '25px', fontSize: 12, color: row.applicationStatus === 1 ? 'green' : row.applicationStatus === 2 ? 'orange' : row.applicationStatus === 3 ? 'red' : 'black', borderColor: row.applicationStatus === 1 ? 'green' : row.applicationStatus === 2 ? 'orange' : row.applicationStatus === 3 ? 'red' : null }}>
                        {row.applicationStatus === 4 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}
                    </StyledButton>
                    // <p style={{ color: stageStatus[row.applicationStatus - 1] == 0 ? 'white' : stageStatus[row.applicationStatus - 1] == 1 ? 'green' : stageStatus[row.applicationStatus - 1] == 2 ? 'orange' : 'red' }}>{row.applicationStatus === 0 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}</p>
                )
            }
        },
        {
            name: 'Date',
            selector: row => moment(row.whenEntered).format('DD MMM YYYY'),
            // width: '13%'
        },
        {
            name: 'Remark',
            selector: row => row.remark,
            // width: '39%',
            wrap: true
        }
    ];
    const yrfStageHistoryColumns = [

        {
            name: 'Stage',
            selector: row => row.stageId === 3 ? 'Director' : yrfStagesListData[row.stageId - 1].label,
            width: '27%',
        },
        {
            name: 'Status',
            width: '17%',
            selector: row => {
                return (
                    // <div style={{ borderWidth: 1, backgroundColor: 'blue', paddingLeft: 10, paddingRight: 10, paddingTop: 8, borderRadius: 10 }}>

                    //     <p style={{ color: row.applicationStatus === 1 ? 'green' : row.applicationStatus === 2 ? 'orange' : 'red', marginTop: 2 }}>{row.applicationStatus === 0 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}</p>
                    // </div>
                    <StyledButton variant="outlined" color="primary" disabled={true} style={{ marginLeft: 0, height: '25px', fontSize: 12, color: row.applicationStatus === 1 ? 'green' : row.applicationStatus === 2 ? 'orange' : row.applicationStatus === 3 ? 'red' : 'black', borderColor: row.applicationStatus === 1 ? 'green' : row.applicationStatus === 2 ? 'orange' : row.applicationStatus === 3 ? 'red' : null }}>
                        {row.applicationStatus === 4 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}
                    </StyledButton>
                    // <p style={{ color: stageStatus[row.applicationStatus - 1] == 0 ? 'white' : stageStatus[row.applicationStatus - 1] == 1 ? 'green' : stageStatus[row.applicationStatus - 1] == 2 ? 'orange' : 'red' }}>{row.applicationStatus === 0 ? 'Not Actioned' : stageStatus[row.applicationStatus - 1].label}</p>
                )
            }
        },
        {
            name: 'Date',
            selector: row => moment(row.whenEntered).format('DD MMM YYYY'),
            width: '13%'
        },
        {
            name: 'Audition Type',
            selector: row => row.auditionType === 1 ? 'On Site' : row.auditionType === 2 ? 'Online' : '',
            width: '13%'
        },
        {
            name: 'Remark',
            selector: row => row.remark,
            width: '30%',
            wrap: true
        }
    ];

    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    const getStageHistory = async () => {
        try {
            if (isOnline) {
                const payload = {
                    applicationId: selectedRowData.applicationId
                    // UserId: 1128
                }
                let postHistoryResponse = await CallAPI(endPointName.GetStageHistoryList, "POST", payload);
                if (postHistoryResponse !== 'error') {
                    setHistoryData(postHistoryResponse.reverse())
                } else {
                    setErrorMsg('Server Error')
                    setErrorPopUp(true)
                }
            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, 'PopUpModal_1');
        }

    }

    const getVideoList = async () => {
        try {
            const payload = {
                UserId: selectedRowData.userId,
                PostId: location === 'PostDetails' ? postData.postId : selectedRowData.postId
            }
            let videoListResponse = await CallAPI(endPointName.GetVideoIdByUserIdAndPostId, "POST", payload);
            console.log(`---------videoListResponse =  `, videoListResponse)
            if (videoListResponse.statusCode === 200 || videoListResponse.statusCode === 404) {
                setVideoDataList(videoListResponse.videoIds)
            } else {
                console.log(videoListResponse);
                setErrorMsg('Server Error')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, 'PopUpModal_2');
        }
    }
    useEffect(() => {
        try {
            if (accessToken !== null) {
                if (isOnline) {
                    getStageHistory();
                    getVideoList();
                } else {
                    setErrorMsg('No Internet')
                    setErrorPopUp(true)
                }
            } else {
                logout()
            }
        } catch (error) {
            catchError(error, 'PopUpModal_3');
        }
    }, [accessToken])

    const handleViewprofileBtn = (row) => {
        try {
            let data = {
                userId: row.userId
            }
            window.sessionStorage.setItem('dataUserId', JSON.stringify(data))
            window.open(`/users/UserDetails/${row.userId}`, '_blank')
        } catch (error) {
            catchError(error, 'PopUpModal_4');
        }
    }

    const onSubmitStage = async () => {
        try {
            if (isOnline) {
                if (userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) {
                    if ((stage !== undefined && status !== undefined && remarks !== undefined && auditionTypeValue !== undefined) && (stage !== '' && status !== '' && remarks !== '' && auditionTypeValue !== '') && (stage !== null && status !== null && remarks !== null && auditionTypeValue !== null)) {
                        callApiOnSubmit();
                    } else {
                        if (stage == undefined || stage == '') {
                            setIsStageError(true);
                        }
                        if (status == undefined || status == '') {
                            setIsStatusError(true);
                        }
                        if (remarks == undefined || remarks == '') {
                            setIsRemarksError(true);
                        }
                        if (auditionTypeValue == undefined || auditionTypeValue == '') {
                            setIsAuditionTypeError(true);
                        }

                    }
                } else {
                    if ((stage !== undefined && status !== undefined && remarks !== undefined) && (stage !== '' && status !== '' && remarks !== '') && (stage !== null && status !== null && remarks !== null)) {
                        callApiOnSubmit();
                    }
                    else {
                        if (stage == undefined || stage == '') {
                            setIsStageError(true);
                        }
                        if (status == undefined || status == '') {
                            setIsStatusError(true);
                        }
                        if (remarks == undefined || remarks == '') {
                            setIsRemarksError(true);
                        }
                    }
                }

            } else {
                setErrorMsg('No Internet')
                setErrorPopUp(true)
            }
        } catch (error) {
            catchError(error, 'PopUpModal_5');
        }
    }

    const callApiOnSubmit = async () => {
        try {
            const accessTokenCheck = window.localStorage.getItem('accessToken')
            if (accessTokenCheck !== null) {
                setAPILoading(true);
                let orgIdFromSession = JSON.parse(sessionStorage.getItem("userJSON"))
                const payload = {
                    StageId: stage.value,
                    ApplicationStatus: status.value,
                    AuditionType: auditionTypeValue == undefined ? 0 : auditionTypeValue.value,
                    Remark: remarks,
                    ApplicationId: selectedRowData.applicationId,
                    CreatedBy: orgIdFromSession.adminUserId,
                    ModifiedBy: orgIdFromSession.adminUserId
                }
                let response = await CallAPI(endPointName.AddPostAppStageStatus, "post", payload);
                if (response.statusCode == 200) {
                    // if (status.value == 3) {
                    if (userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) {
                        if (videoDataList !== '') {
                            const payload1 = {
                                VideoId: videoDataList,
                                UserId: selectedRowData.userId,
                                PostId: location === 'PostDetails' ? postData.postId : selectedRowData.postId,
                                StageId: stage.value,
                            }
                            let updateVideoStageResponse = await CallAPI(endPointName.UpdateAuditionVideo, "POST", payload1);
                            if (updateVideoStageResponse === 'error') {
                                setErrorMsg('Server Error')
                                setErrorPopUp(true)
                            }
                        }
                    }
                    // const payload = {
                    //     deviceIds: [],
                    //     notificationTitle: `You have been ${status.value === 3 ? 'Rejected' : status.label} by ${stage.label} for ${location === 'PostDetails' ? postData.projectName : selectedRowData.projectName}`,
                    //     notificationMessage: `${status.value === 3 ? 'Rejected' : status.label}`,
                    //     notificationType: 1,
                    //     sendToAll: 0,
                    //     postId: location === 'PostDetails' ? postData.postId : selectedRowData.postId,
                    //     OrganizationId: orgIdFromSession.organizationId,
                    //     UserIds: [location === 'PostDetails' ? JSON.stringify(selectedRowData.userId) : JSON.stringify(userId)]
                    // }
                    let auditionTile = location === 'PostDetails' ? postData.title : selectedRowData.title;
                    if (userFromSession.roleId === 1) {
                        const payload = {
                            deviceIds: [],
                            notificationTitle: `Audition Update - ${location === 'PostDetails' ? postData.projectName : selectedRowData.projectName}`,
                            notificationMessage: `${status.value === 3 ? `${auditionTile} \n${stageNotifMsgRejected}` : status.value === 2 ? `${auditionTile} \n${stageNotifMsgOnHold.replace('{stage_value}', stage.label)}` : `${auditionTile} \n${stageNotifMsgSeleceted.replace('{stage_value}', stage.label)}`}`,
                            notificationType: 1,
                            sendToAll: 0,
                            postId: location === 'PostDetails' ? postData.postId : selectedRowData.postId,
                            OrganizationId: orgIdFromSession.organizationId,
                            UserIds: [location === 'PostDetails' ? JSON.stringify(selectedRowData.userId) : JSON.stringify(userId)]
                        }
                        let response = await CallAPI(endPointName.AuditionNotification, 'post', payload);
                        console.log("Notification Response ==> ", response)
                        if (response !== 'error') {
                            setAPILoading(false);
                            onSubmitStageData()
                        } else {
                            setErrorMsg('Server Error')
                            setErrorPopUp(true)
                        }
                    }
                } else {
                    setErrorMsg('Server Error')
                    setErrorPopUp(true)
                }
            } else {
                logout()
            }
        } catch (error) {
            catchError(error, 'PopUpModal_6');
        }
    }

    const onChangeValues = (newValue, type) => {
        try {
            if (userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) {
                if (type == 'Stage') {
                    setStage(newValue);
                    setIsStageError(false)
                } else if (type == 'Status') {
                    setStatus(newValue);
                    setIsStatusError(false)
                } else if (type == 'AuditionType') {
                    setAuditionTypeValue(newValue);
                    setIsAuditionTypeError(false)
                }
            } else {

                if (selectedRowData.applicationStatus === 3) {
                    setStage(null);
                    setStatus(null);
                    setAuditionTypeValue(null);
                    setPopUp(true);
                } else {
                    if (type == 'Stage') {
                        setStage(newValue);
                        setIsStageError(false)
                    } else if (type == 'Status') {
                        setStatus(newValue);
                        setIsStatusError(false)
                    } else if (type == 'AuditionType') {
                        setAuditionTypeValue(newValue);
                        setIsAuditionTypeError(false)
                    }
                }
            }
        } catch (error) {
            catchError(error, 'PopUpModal_7');
        }
    }

    const handleServerError = () => {
        try {
            setErrorPopUp(false)
            setErrorMsg('')
        } catch (error) {
            catchError(error, "CreateUpdateProject_8")
        }
    }

    return (
        <>
            <Dialog
                open={openStageDialog}
                fullWidth={true}
                maxWidth={'md'}
                onClose={handleCloseDialog}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" marginBottom={2}>
                    {firstName + " " + lastName}
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            // top: 8,
                            color: "grey",
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* By changing the stage will change the current stage level to the selected stage. */}
                        {
                            (userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ?
                                <>
                                    Current Stage: {selectedRowData.stage === 0 ? 'Stage 1 (Casting Director)' : yrfStagesListData[selectedRowData.stage - 1].label} - {selectedRowData.applicationStatus === 4 ? 'Not Actioned' : stageStatus[selectedRowData.applicationStatus - 1].label}
                                </>
                                :
                                <>
                                    Current Stage: {selectedRowData.stage === 0 ? 'Stage 1 (Assistant Casting Director)' : stagesListData[selectedRowData.stage - 1].label} - {selectedRowData.applicationStatus === 4 ? 'Not Actioned' : stageStatus[selectedRowData.applicationStatus - 1].label}
                                </>
                        }
                    </DialogContentText>
                    <Grid container columns={{ xs: 6, sm: 8, md: 12 }} style={{ paddingTop: 10 }}>
                        <Grid item xs={6} md={(userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ? 4 : 6} style={{ padding: 5 }}>
                            <Autocomplete
                                // options={selectedRowData.stage == 0 ? userFromSession.organizationId === 4 ? yrfStagesListData : stagesListData : stagesListData.filter((el) => {
                                //     if (el.value == selectedRowData.stage && selectedRowData.applicationStatus !== 1) {
                                //         return true
                                //     } else if (el.value > selectedRowData.stage) {
                                //         return true
                                //     } else {
                                //         return false
                                //     }
                                // })}
                                options={
                                    // (userFromSession.organizationId === 4 ) ? yrfStagesListData : stagesListData
                                    (userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ?
                                        yrfStagesListData.filter((el) => {
                                            if (el.value === userFromSession.roleId) {
                                                return true
                                            }
                                            else {
                                                return false
                                            }
                                            // else if (el.value > selectedRowData.stage) {
                                            //     return true
                                            // }
                                        })
                                        :
                                        selectedRowData.stage == 0 ? stagesListData : stagesListData.filter((el) => {
                                            if (el.value == selectedRowData.stage && selectedRowData.applicationStatus !== 1) {
                                                return true
                                            } else if (el.value > selectedRowData.stage) {
                                                return true
                                            } else {
                                                return false
                                            }
                                        })

                                    // (userFromSession.organizationId === 4 || userFromSession.organizationId === 7) ?
                                    //     selectedRowData.stage == 0 ? yrfStagesListData : yrfStagesListData.filter((el) => {
                                    //         if (el.value == selectedRowData.stage && selectedRowData.applicationStatus !== 1) {
                                    //             return true
                                    //         } else if (el.value > selectedRowData.stage) {
                                    //             return true
                                    //         } else {
                                    //             return false
                                    //         }
                                    //     })
                                    //     :
                                    //     selectedRowData.stage == 0 ? stagesListData : stagesListData.filter((el) => {
                                    //         if (el.value == selectedRowData.stage && selectedRowData.applicationStatus !== 1) {
                                    //             return true
                                    //         } else if (el.value > selectedRowData.stage) {
                                    //             return true
                                    //         } else {
                                    //             return false
                                    //         }
                                    //     })

                                }
                                // getOptionDisabled={(option) =>
                                //     userFromSession.organizationId == 4 &&
                                //         userFromSession.roleId == 2 ?
                                //         option === yrfStagesListData[1]
                                //         :
                                //         option === yrfStagesListData[0]

                                // }
                                getOptionLabel={(option) => option.label}
                                onChange={(_, newValue) => {
                                    onChangeValues(newValue, 'Stage');
                                }}
                                value={stage}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Stage*"
                                        variant="outlined"
                                        fullWidth
                                        value={stage}
                                        validators={["required"]}
                                        error={isStageError}
                                        helperText={isStageError ? "Stage is required" : ''}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} md={(userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ? 4 : 6} style={{ padding: 5 }}>
                            <Autocomplete
                                options={stageStatus}
                                getOptionLabel={(option) => option.label}
                                onChange={(_, newValue) => {
                                    onChangeValues(newValue, 'Status');
                                }}
                                value={status}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label="Select Status*"
                                        variant="outlined"
                                        fullWidth
                                        value={status}
                                        validators={["required"]}
                                        // errorMessages={["Project is required"]}
                                        error={isStatusError}
                                        helperText={isStatusError ? "Status is required" : ''}
                                    />
                                )}
                            />
                        </Grid>
                        {(userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) &&
                            <Grid item xs={6} md={4} style={{ padding: 5 }}>
                                <Autocomplete
                                    options={auditionTypes}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(_, newValue) => {
                                        onChangeValues(newValue, 'AuditionType');
                                    }}
                                    value={auditionTypeValue}
                                    renderInput={(params) => (
                                        <TextField {...params}
                                            label="Select Audition Type*"
                                            variant="outlined"
                                            fullWidth
                                            value={auditionTypeValue}
                                            validators={["required"]}
                                            // errorMessages={["Project is required"]}
                                            error={isAuditionTypeError}
                                            helperText={isAuditionTypeError ? "Audition Type is required" : ''}
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} style={{ padding: 5 }}>
                            <TextField
                                margin="dense"
                                id="name"
                                label="Remark*"
                                type="text"
                                multiline={true}
                                placeholder={"Enter remark*"}
                                maxLength="200"
                                disabled={(userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ? false : selectedRowData.applicationStatus === 3 ? true : false}
                                onChange={(e) => { setRemarks(e.target.value); setIsRemarksError(false) }}
                                rows={3}
                                style={{ width: '100%', marginTop: 0 }}
                                value={remarks}
                                validators={["required"]}
                                error={isRemarksError}
                                helperText={isRemarksError ? "Remark is required" : ''}

                            />
                        </Grid>

                    </Grid>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                        <DataTable
                            columns={(userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ? yrfStageHistoryColumns : stageHistoryColumns}
                            data={historyData}
                            // selectableRowsComponentProps={selectProps}
                            // onSelectedRowsChange={handleRowSelected}
                            highlightOnHover
                            // dense
                            // pagination
                            responsive
                            // progressPending={''}
                            // customStyles={customStyles}
                            // onRowClicked={rowClicked}
                            progressComponent={
                                // <CircularProgress
                                //   style={{ backgroundColor: '#FFF', marginTop: 20, marginBottom: 40 }}
                                //   className="progress" />
                                // <img src="/assets/images/loader.svg" alt="" style={{ height: 100, width: 100, marginTop: 20, marginBottom: 40 }} />
                                <img src="/assets/images/reel.gif" alt="" style={{ height: 80, width: 80, marginTop: 20, marginBottom: 40 }} />
                            }
                        />
                    </div>

                </DialogContent>
                <Divider style={{ backgroundColor: '#F7F7F7', }} />
                <DialogActions style={{ display: 'flex', justifyContent: isNonMobile == false && 'center' }}>
                    {location === 'PostDetails' &&
                        <Button onClick={() => handleViewprofileBtn(selectedRowData)} sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginRight: 20 }} >
                            View Profile
                        </Button>
                    }
                    <Button disabled={(userFromSession.organizationId === 4 || userFromSession.organizationId === 7 || userFromSession.organizationId === 1) ? false : selectedRowData.applicationStatus === 3 ? true : false} onClick={onSubmitStage} sx={{ ":hover": { backgroundColor: "#ea232a" }, backgroundColor: '#e0746e' }} variant="contained" style={{ marginRight: 20, marginTop: 5, marginBottom: 5 }} >
                        {APILoading ?
                            <CircularProgress size={20} color='inherit' sx={{ alignItems: 'center' }} />
                            :
                            'Submit'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            {
                popUp &&
                <ValidationPopUp
                    openModal={popUp}
                    handleClose={() => {
                        setPopUp(false);
                        setStage(null);
                        setStatus(null);
                        setAuditionTypeValue(null);
                    }}
                    contentText={'Stage cannot be modified since the selection process of this performer is completed.'}
                />
            }
            {
                errorPopUp &&
                <ServerErrorModal
                    popUpFor={errorMsg}
                    openAlert={errorPopUp}
                    handleCloseAlert={handleServerError}
                />
            }
        </>
    );
};
