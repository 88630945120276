import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const UserList = Loadable(lazy(() => lazyRetry(() => import('./UserList'), 'userList')));
const UserDetails = Loadable(lazy(() => lazyRetry(() => import('./UserDetails'), 'userDetails')));
const UserManagement = Loadable(lazy(() => lazyRetry(() => import('./UserManagement'), 'userManagement')));
const Feedback = Loadable(lazy(() => lazyRetry(() => import('./Feedback'), 'feedBack')));
const Configure = Loadable(lazy(() => lazyRetry(() => import('./Configure'), 'configure')));

const lazyRetry = function (componentImport, name) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
        );
        componentImport().then((component) => {
            window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
            resolve(component);
        }).catch((error) => {
            if (!hasRefreshed) { // not been refreshed yet
                window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
                return window.location.reload(); // refresh the page
            }
            reject(error); // Default error behaviour as already tried refresh

        });
    });
};



const userRoutes = [
    {
        path: '/users/UserList', element: <UserList />,
    },
    {
        path: '/users/UserDetails/:id', element: <UserDetails />
    },
    {
        path: '/users/UserManagement', element: <UserManagement />
    },
    {
        path: '/users/Feedback', element: <Feedback />
    },
    {
        path: '/users/Configure', element: <Configure />
    }
];

export default userRoutes;