import { useMsal } from '@azure/msal-react';
import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material';
import { loginRequest } from 'authConfig';
import React, { useState } from 'react';
// import { Button } from 'react-bootstrap';
// import DocumentTitle from 'react-document-title';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
// import LuvFilmsLogo from '../../../images/LuvFilms.png'
// import Form from 'react-bootstrap/Form';
// import { FaFacebook, FaYoutube } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import './css/main.css';
// import './css/plugins.css'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const HomePage = () => {
    const { instance, inProgress } = useMsal();
    // let user = JSON.parse(sessionStorage.getItem("userJSON"));
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname;

    if (path == "/adminlogin") {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    }

    return (
        // <DocumentTitle title={'Home Page'}>
        <div lang="en">


            {/* <head> */}
            <Helmet>

                <meta charset="utf-8" />
                <meta name="robots" content="noodp" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />

                <title>DirectorOfCasting</title>

                <link rel="shortcut icon" href="public/assets/img/favicon.png" />

                <link href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&amp;subset=latin-ext" rel="stylesheet" />
                <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
                {/* <script type="text/javascript" src="js/plugins.js"></script>
                <script type="text/javascript" src="js/main.js"></script> */}
            </Helmet>

            {/* </head> */}

            <div>
                {/* <div className="preloader">

                    <div className="spinner">

                        <div className="bounce-1"></div>
                        <div className="bounce-2"></div>
                        <div className="bounce-3"></div>

                    </div>

                </div> */}


                <div className="hero">

                    <div className="front-content">

                        {/* <p style="font-size: 24px;align-self: center;text-align: center;margin-top: 50px;">DirectorOfCasting</p> */}

                        <div className="container-mid">

                            <div className="animation-container animation-fade-down" data-animation-delay="0">

                            </div>
                            <div className="animation-container animation-fade-right" data-animation-delay="300">

                                <h1 className="doc_h1">Coming Soon</h1>

                            </div>

                            <div className="animation-container animation-fade-left" data-animation-delay="600">

                            </div>


                        </div>
                        <div className="doc_footer">

                            <div className="animation-container animation-fade-up" data-animation-delay="1200">
                                <p>© 2023 DirectorOfCasting | All rights reserved | Contact us: connect@directorofcasting.com</p>

                            </div>
                        </div>
                    </div>
                    <div className="background-content parallax-on">
                        <div className="background-overlay"></div>
                        <div className="background-img layer" data-depth="0.05"></div>
                    </div>
                </div>
            </div>
            {/* </head> */}
            {/* <Helmet>
                <script type="text/javascript" src="js/plugins.js"></script>
                <script type="text/javascript" src="js/main.js"></script>
            </Helmet> */}
        </div>

    )
}

export default HomePage;